<template>
	<div class="modal modal-story" :id="`modal-stories-${store.id}`" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true" oncontextmenu="return false;">
		<div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
			<div class="modal-content">
				<div class="social-stories sc-stories" :class="{ 'isPaused': isPaused }">
					<div class="timeline">
						<div class="slice" v-for="(story, index) in stories" v-bind:key="index">
							<div class="progress"></div>
						</div>
					</div>
					<div class="header">
						<a href="javascript:void(0)" class="d-flex align-items-center" @click="seeMore">
							<div class="avatar avatar-xs rounded-circle">
								<img :src="store.logo_url" alt="logo">
							</div>
							<div class="ps-3 text-md text-white">{{ store.name }}</div>
						</a>
					</div>
					<div class="see-more-wrapper">
						<img :src="image" class="img" @load="imgReady = true" v-show="imgReady" v-touch:hold="touchHold" v-touch:release="touchRelease" v-touch:swipe.top="touchSwipeTop" v-touch:swipe.bottom="touchSwipeBottom" @dragstart.prevent>
						<div class="img d-flex" v-if="!imgReady">
							<div class="spinner-border text-light m-auto" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
						<a href="javascript:void(0)" class="see-more" @click="seeMore">
							<span class="see-more-text" style="opacity: 0.9;">Ver más</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import { Modal } from 'bootstrap';
	import anime from 'animejs/lib/anime.es.js';
	import _ from 'lodash';

	export default {
		emits: ['setVisible'],
		props: {
			store: Object,
			storiesVisible: Boolean
		},
		setup(props, { emit }) {
			const timeout = ref(null);
			const modalStories = ref(null);
			const current = ref(0);
			const timeline = ref(null);
			const imgReady = ref(false);
			const isPaused = ref(false);
			const time = 5000;

			const stories = _.map(props.store.stories_active, (data) => {
				return {
					id: data.id,
					url: data.image_url
				};
			});

			const image = computed(() => {
				if(!props.storiesVisible) return null;
				else return stories[current.value].url;
			});

			const allStoriesEnd = () => {
				emit('setVisible', false);
			}

			const seeMore = () => {
				if(timeout.value) clearTimeout(timeout.value);
				timeout.value = setTimeout(() => {
					emit('setVisible', false);
					router.push({name: 'store.home', params: {slug: props.store.slug}});
				}, 250);
			}

			const close = (next = true) => {
				if(timeout.value) clearTimeout(timeout.value);
				timeout.value = setTimeout(() => {
					emit('setVisible', false);

					if(next) {
						var elements = document.querySelectorAll('.stories-wrapper .avatar-new');
						if(elements.length > 0) elements[0].click();
					}
				}, 250);
			}

			onMounted(() => {
				modalStories.value = new Modal(document.getElementById(`modal-stories-${props.store.id}`));
				document.getElementById(`modal-stories-${props.store.id}`).addEventListener('shown.bs.modal', () => {
					if(document.body.clientWidth <= 576) document.querySelector('body').style.position = 'fixed';
				});
				document.getElementById(`modal-stories-${props.store.id}`).addEventListener('hidden.bs.modal', () => {
					document.querySelector('body').style.position = 'unset';
					emit('setVisible', false);
					timeline.value.pause();
				});

				timeline.value = anime.timeline({ autoplay: false, duration: time, easing: 'linear' });
				_.forEach(stories, function(story, index) {
					timeline.value.add({
						targets: document.querySelectorAll(`#modal-stories-${props.store.id} .timeline > .slice`)[index].children[0],
						width: '100%',
						changeBegin: () => {
							current.value = index;
							store.dispatch('interaction/addStory', stories[index].id);
						}
					});
				});
			});

			onUnmounted(() => {
				timeline.value.remove();
			});

			const touchHold = () => {
				timeline.value.pause();
				isPaused.value = true;
			}

			const touchRelease = (e) => {
				if(isPaused.value) {
					timeline.value.play();
					isPaused.value = false;
					return false;
				}

				var touchX = ('changedTouches' in e) ? e.changedTouches[0].clientX : e.clientX;

				if(touchX > window.innerWidth / 2) {
					if(current.value < stories.length - 1) {
						current.value += 1;

						timeline.value.pause();
						timeline.value.seek(current.value * time);
						timeline.value.play();
					}else{
						close();
					}
				}else{
					if(current.value > 0) {
						current.value -= 1;

						timeline.value.pause();
						timeline.value.seek(current.value * time);
						timeline.value.play();
					}
				}
			}

			const touchSwipeTop = () => seeMore();
			const touchSwipeBottom = () => close(false);

			watch(() => props.storiesVisible, (newValue) => {
				if(newValue) {
					modalStories.value.show();
					timeline.value.restart();
					if(!imgReady.value) timeline.value.pause();
					timeline.value.finished.then(close);
					isPaused.value = false;
				}else{
					modalStories.value.hide();
				}
			});

			watch(current, () => imgReady.value = false);
			watch(imgReady, (newValue) => {
				if(!newValue) timeline.value.pause();
				if(newValue && props.storiesVisible) timeline.value.play();
			});

			return { stories, image, allStoriesEnd, seeMore, current, imgReady, isPaused, touchHold, touchRelease, touchSwipeTop, touchSwipeBottom };
		}
	}
</script>

<style scoped>
	.spinner-border {
		border-width: .12em !important;
	}

	.ig-stories .see-more {
		cursor: pointer;
	}

	.modal-dialog {
		margin: 0px auto !important;
		max-height: var(--doc-height) !important;
	}
</style>
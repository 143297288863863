<template>
	<div class="modal" id="modal-info" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content shadow-3" v-if="isDefined">
				<div class="modal-header">
					<store-card-small :store="current_store" :subtitle="current_store.description" />
				</div>
				<div class="modal-body p-0">
					<div class="list-group list-group-flush lh-sm">
						<div class="list-group-item hstack gap-3 align-items-center px-6 py-3" v-if="showPaymentMethods && allowCash">
							<div class="flex-fill">
								<div class="text-sm fw-bold">Pagos en la entrega:</div>
								<div class="text-sm">Puedes pagar en efectivo</div>
							</div>
							<i class="bi bi-cash text-lg"></i>
						</div>
						<div class="list-group-item hstack gap-3 align-items-center px-6 py-3" v-if="showPaymentMethods && allowCards">
							<div class="flex-fill">
								<div class="text-sm fw-bold">Pagos online:</div>
								<div class="text-sm">Tarjetas Visa, Mastercard, Amex, y más!</div>
							</div>
							<i class="bi bi-credit-card text-lg"></i>
						</div>
						<div class="list-group-item px-6 py-3">
							<div class="hstack gap-3 align-items-center">
								<div class="flex-fill">
									<div class="text-sm fw-bold">Ubicación:</div>
									<div class="text-sm">{{ current_store.address.complete }}</div>
								</div>
								<i class="bi bi-geo-alt text-lg"></i>
							</div>
							<div class="border rounded-2 overflow-hidden position-relative my-2" v-if="current_store?.address?.geolocation && addressImageShow">
								<img :src="addressImageSrc" class="img-fluid" @load="addressImageReady = true" v-show="addressImageReady">
								<Skeletor width="100%" class="rounded" style="aspect-ratio: 2 / 1;" v-show="!addressImageReady" />
								<a :href="`https://www.google.com/maps/dir/?api=1&destination=${current_store.address.geolocation.coordinates.join(',')}`" target="_blank" class="btn btn-sm btn-neutral position-absolute end-0 top-0 m-2">Abrir</a>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer justify-content-between">
					<div class="hstack gap-3">
						<a target="_blank" v-bind:href="'https://wa.me/'+current_store.phone" class="btn btn-sm btn-secondary" v-if="!!current_store.phone">Contactar</a>
						<router-link :to="{name: 'store.links'}" class="btn btn-sm btn-outline-dark" v-if="current_store.links.length > 0">Links</router-link>
					</div>
					<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Cerrar</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import { Modal } from 'bootstrap';
	import _ from 'lodash';
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const { isDefined, current_store } = composableStore();

			const allowCash = computed(() => {
				return _.find(current_store.value.payment_methods, (row) => { return row.type == 'cash' });
			});

			const allowCards = computed(() => {
				return _.find(current_store.value.payment_methods, (row) => { return ['mercadopago-online', 'bancard'].includes(row.type) });
			});

			const addressImageShow = ref(false);
			const addressImageReady = ref(false);
			const addressImageSrc = computed(() => {
				if(current_store.value?.address?.geolocation) return `https://maps.googleapis.com/maps/api/staticmap?center=${current_store.value.address.geolocation.coordinates.join(',')}&zoom=17&size=600x300&maptype=roadmap&markers=${current_store.value.address.geolocation.coordinates.join(',')}&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`;
				else return null;
			});

			const modalView = ref(null);
			onMounted(() => {
				modalView.value = new Modal(document.getElementById('modal-info'));

				document.getElementById('modal-info').addEventListener('shown.bs.modal', () => addressImageShow.value = true);
				document.getElementById('modal-info').addEventListener('hidden.bs.modal', () => {
					addressImageShow.value = false;
					addressImageReady.value = false;
				});
			});

			const showPaymentMethods = computed(() => {
				return (_.indexOf(['store'], current_store.value.type) !== -1);
			});

			return { isDefined, current_store, allowCash, allowCards, addressImageShow, addressImageReady, addressImageSrc, showPaymentMethods };
		}
	}
</script>
<template>
	<div>
		<header>
			<div class="container py-5">
				<div class="row">
					<div class="col d-flex align-items-center">
						<common-button-back :redirect="buttonBack" />
					</div>
					<div class="col-5 d-flex align-items-center text-end" v-if="['store.item', 'store.links'].indexOf(route.name) !== -1 && redirectBackInfo?.name != 'store.home'">
						<router-link :to="{ name: 'store.home' }" class="h5 ls-tight ms-auto link-dark">Ir al comercio</router-link>
					</div>
				</div>
			</div>
		</header>
		<div class="container">
			<div class="justify-content-center">
				<div class="d-flex flex-column position-relative">
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { useRoute } from 'vue-router';
	import composableRedirect from '@/composables/redirect';

	export default {
		props: {
			buttonBack: Object
		},
		setup() {
			const route = useRoute();
			const { redirectBackInfo } = composableRedirect();

			return { route, redirectBackInfo };
		}
	}
</script>

<style scoped>
	.container {
		max-width: 650px !important;
	}
</style>
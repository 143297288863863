import Repository from "./Repository";

const resource = "/stores";

export default {
	getList(filters) {
		return Repository.patch(resource, filters);
	},
	getStatus(ids) {
		return Repository.post(`${resource}/status`, { ids: ids });
	}
}
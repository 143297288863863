<template>
	<teleport to="body">
		<div id="menu-bancard-register" class="modal" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
				<div class="modal-content shadow-3 overflow-hidden">
					<div class="modal-header">
						<h5 class="modal-title">Registrar tarjeta</h5>
						<div class="text-xs ms-auto">
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
					</div>
					<div id="bancard-iframe-container" class="modal-body"></div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import store from '@/store';
	import { Modal } from 'bootstrap';
	import StoreRepository from '@/repositories/StoreRepository';
	import composableForm from '@/composables/form';

	export default {
		emits: ['setCard'],
		props: {
			constraints: Object
		},
		setup(props) {
			const { catchError } = composableForm();
			const modal = ref(null);
			const formLoaded = ref(false);
			var Bancard = {};

			onMounted(() => {
				modal.value = new Modal(document.getElementById('menu-bancard-register'));

				document.getElementById(`menu-bancard-register`).addEventListener('shown.bs.modal', () => {
					if(formLoaded.value) return;

					StoreRepository.cardsCreate(store.getters['cart/slug'], { gateway: props.constraints.gateway })
						.then((response) => {
							var script = document.createElement('script');
								script.src = `https://vpos.infonet.com.py${(process.env.NODE_ENV != 'production') ? ':8888' : ''}/checkout/javascript/dist/bancard-checkout-3.0.0.js`;
								script.async = true;

							script.onload = async () => {
								console.log('bancard...', Bancard);
								Bancard.Cards.createForm('bancard-iframe-container', response.data.process_id, {
									'button-background-color': '#111827',
									'button-text-color': '#FFF',
									'button-border-color': '#111827'
								});

								formLoaded.value = true;
							};

							document.head.appendChild(script);
						}).catch((e) => {
							catchError(e, true);
							modal.value.hide();
						});
				});
			});

			return { modal };
		}
	}
</script>
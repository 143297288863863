<template>
	<div class="text-warning hstack gap-1" :class="wrapperClass">
		<i class="bi bi-info-circle-fill"></i>
		<span>{{ text }}</span>
	</div>
</template>

<script>
	export default {
		props: {
			text: String,
			wrapperClass: {
				type: String,
				default: 'text-sm lh-xs align-items-start'
			}
		}
	}
</script>
<template>
	<div class="mt-3 mx-n6 px-5 stories-wrapper" v-if="list.length > 0">
		<store-stories-avatar v-for="store in list" v-bind:key="store.id" :store="store" />
	</div>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';
	import _ from 'lodash';

	export default {
		setup() {
			const stores = computed(() => store.getters['stores/list']);
			const vieweds = computed(() => store.getters['interaction/stories']);

			const list = computed(() => {
				var data = [];

				if(stores.value.length > 0) {
					data = _.filter(stores.value, function(info) { return (info.stories_active.length > 0); });
					//data = _.map(data, ['name', 'stories_active']);
					//data = _.orderBy(data, ['created_at'], ['desc']);
					data = _.sortBy(data, [function(store) {
						var hasNew = _.find(store.stories_active, function(data) { return (_.indexOf(vieweds.value, data.id) == -1); });
						return (hasNew);
					}]);
				}

				return data;
			});

			return { list };
		}
	}
</script>

<style>
	.stories-wrapper {
		white-space: nowrap !important;
		overflow-x: auto !important;
		flex-wrap: nowrap;
	}

	.stories-wrapper > *:not(:first-child) {
		margin-left: 0.25rem !important;
	}

	.stories-wrapper::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	.stories-wrapper {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
</style>
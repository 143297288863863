<template>
	<hr class="my-0" v-if="index" />
	<div class="d-flex justify-content-between p-4">
		<div class="form-check position-relative">
			<input type="radio" v-model="option" value="bank-transfer" class="form-check-input" :disabled="loading">
			<div class="form-check-label text-dark d-block ms-2">
				<a href="javascript:void(0)" class="stretched-link text-dark" @click="select">Transferencia bancaria</a>
				<small class="d-block lh-sm" v-if="option == 'bank-transfer' && payment_method.description">Presiona <b>información</b> para saber cómo realizar la transferencia</small>
			</div>
		</div>
		<a href="javascript:void(0)" class="text-dark" v-if="option == 'bank-transfer' && payment_method.description" @click="menuShow">Información</a>
	</div>

	<cart-checkout-menu-bank-transfer :payment_method="payment_method" />
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import store from '@/store';
	import { Offcanvas } from 'bootstrap';

	export default {
		emits: ['select'],
		props: {
			loading: Boolean,
			payment_method: Object,
			index: Number
		},
		setup(props, { emit }) {
			const payment = computed(() => store.getters['cart/payment']);
			const option = computed(() => {
				if(!payment.value) return null;
				else return payment.value.option;
			});

			const description = computed(() => {
				var description = null;

				switch(payment.value.type) {
					case 'exact':
						description = `Paga <b>con importe exacto</b>`;
						break;
					case 'other':
						description = `Paga <b>con $ ${payment.value.amount}</b>`;
						break;
					default:
						description = `Alias: ramiro.llanos (Titular: Ramiro Llanos).`;
						break;
				}

				return description;
			});

			const select = () => {
				emit('select', { option: 'bank-transfer' });
			}

			const menu = ref(null);
			onMounted(() => menu.value = new Offcanvas(document.getElementById('menu-bank-transfer')));
			const menuShow = () => {
				if(!props.loading) menu.value.show();
			}

			return { payment, description, option, select, menuShow };
		}
	}
</script>

<style scoped>
	.form-check-label {
		font-size: 1rem !important;
		font-weight: normal !important;
	}
</style>
<template>
	<div class="d-flex justify-content-between align-items-center">
		<div class="d-flex align-items-center">
			<div>
				<Skeletor width="46" height="46" class="rounded" />
			</div>
			<div class="ps-3">
				<Skeletor width="150" class="stretched-link h5 mb-0" />
				<Skeletor width="170" class="d-block text-sm text-muted mt-1" />
			</div>
		</div>
		<div class="d-flex align-items-center text-end">
			<Skeletor width="70" height="23" class="rounded-pill" />
		</div>
	</div>
</template>
import Repository from "./Repository";

export default {
	getProfile(slug) {
		var resource = `/stores/${slug}/profile`;
		return Repository.get(resource);
	},
	getExtras(slug, address = null) {
		var resource = `stores/${slug}/extras`;
		return Repository.get(resource, { params: { address: address } });
	},
	rateDelivery(slug, payload) {
		var resource = `stores/${slug}/delivery/pricing`;
		return Repository.post(resource, payload);
	},
	getCatalogItems(slug) {
		var resource = `catalog/${slug}/items`;
		return Repository.get(resource);
	},
	getCatalogSections(slug) {
		var resource = `catalog/${slug}/sections`;
		return Repository.get(resource);
	},
	getPromocode(slug, payload) {
		var resource = `stores/${slug}/promocode`;
		return Repository.post(resource, payload);
	},
	cardsList(slug, payload) {
		var resource = `stores/${slug}/payment/cards/list`;
		return Repository.post(resource, payload);
	},
	cardsCreate(slug, payload) {
		var resource = `stores/${slug}/payment/cards/create`;
		return Repository.post(resource, payload);
	},
	cardsDestroy(slug, payload) {
		var resource = `stores/${slug}/payment/cards/destroy`;
		return Repository.delete(resource, { params: payload });
	}
}

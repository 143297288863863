<template>
	<h5 class="mb-4">¿Cómo lo querés pagar?</h5>
	<Skeletor height="100" class="card bg-white shadow-1 border rounded-2" v-if="!displayOptions" />
	<div class="card bg-white shadow-1 border rounded-2" v-if="displayOptions">
		<component :is="`cart-checkout-payment-${payment_method.type}`" :index="index" :payment_method="payment_method" :loading="loading" @select="select" v-for="(payment_method, index) in paymentMethods" v-bind:key="index"></component>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';
	import _ from 'lodash';

	export default {
		emits: ['updateLoading'],
		props: {
			loading: Boolean,
			displayOptions: Boolean,
			store: Object
		},
		setup(props, { emit }) {
			const select = (option) => {
				if(props.loading) return;

				emit('updateLoading', true);
				store.dispatch('cart/setPayment', option);
				emit('updateLoading', false);
			}

			const hasCode = computed(() => store.getters['code/isDefined']);
			const hasCodeGroup = computed(() => {
				if(hasCode.value) return store.getters['code/group'];
				else return false;
			});

			const paymentMethods = computed(() => {
				var payment_methods = _.clone(props.store.payment_methods);

				if(hasCode.value && !hasCodeGroup.value) {
					_.remove(payment_methods, { type : 'cash' });
					payment_methods = _.concat({ 'type': 'none', 'title': 'Ninguno', 'frequent': null, 'constraints': [] }, payment_methods);
				}

				return payment_methods;
			});

			return { select, paymentMethods };
		}
	}
</script>

<style scoped>
	.form-check-label {
		font-size: 1rem !important;
		font-weight: normal !important;
	}
</style>
<template>
	<form :id="`group-${group.id}`" class="mt-5">
		<h4 class="h4">{{ group.name }}</h4>
		<small class="text-danger" v-if="!satisfy_constraints && group.constraints.min != group.constraints.max">Debes escoger entre {{ group.constraints.min }} y {{ group.constraints.max }} opciones</small>
		<small class="text-danger" v-if="!satisfy_constraints && group.constraints.min == group.constraints.max">Debes escoger {{ group.constraints.min }} {{ ((group.constraints.min == 1) ? 'opción' : 'opciones') }}</small>
		<small class="text-success" v-if="satisfy_constraints && additionals_count == group.constraints.max">Completaste la selección</small>
		<small class="text-warning" v-if="satisfy_constraints && additionals_count < group.constraints.max">Puedes seleccionar hasta {{ group.constraints.max }} {{ ((group.constraints.max == 1) ? 'opción' : 'opciones') }}</small>
		<hr class="mt-2 mb-0" />
		<div class="list-group list-group-flush">
			<catalog-additional-card v-for="additional in group.additionals" v-bind:key="additional.id" :additional="additional" :remaining="max_constraint_count" :disabled="disabled && !is_selected(additional.id)" @updated="updated" />
		</div>
	</form>
</template>

<script>
	import { ref, computed, watch } from 'vue';
	import _ from 'lodash';

	export default {
		props: {
			group: Object
		},
		setup(props, { emit }) {
			const selection = ref([]);
			const disabled = ref(false);

			const updated = (data) => {
				_.remove(selection.value, (row) => { return row.id == data.id; });
				if(data.quantity > 0) selection.value.push({id: data.id, name: data.name, unit_price: data.unit_price, quantity: data.quantity});
			}

			const is_selected = (additional_id) => {
				return (_.find(selection.value, (row) => { return row.id == additional_id })) ? true : false;
			};

			const additionals_count = computed(() => {
				var count = 0;
				_.forEach(selection.value, (row) => count+= row.quantity);

				return count;
			});

			const max_constraint_count = computed(() => {
				return (props.group.constraints.max - additionals_count.value);
			});

			const satisfy_constraints = computed(() => {
				return (props.group.constraints.min <= additionals_count.value && additionals_count.value <= props.group.constraints.max);
			});

			watch(selection.value, () => {
				disabled.value = (additionals_count.value == props.group.constraints.max);

				// Emitir evento
				emit('update', {
					group_id: props.group.id,
					satisfy: satisfy_constraints.value,
					constraints: JSON.parse(JSON.stringify(props.group.constraints)),
					additionals: JSON.parse(JSON.stringify(selection.value))
				});
			});

			return { disabled, updated, is_selected, satisfy_constraints, additionals_count, max_constraint_count };
		}
	}
</script>
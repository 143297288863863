<template>
	<div class="modal" id="modal-discount" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content shadow-3">
				<div class="modal-header">
					<h5 class="modal-title">{{ discount.name }}</h5>
					<div class="text-xs ms-auto">
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
				</div>
				<div class="modal-body">
					<!-- Text -->
					<p class="lh-xl mb-1">Esta promoción tiene las siguientes condiciones:</p>
					<!-- List -->
					<ul class="list-unstyled mb-0">
						<li class="pt-3">
							<div class="d-flex align-items-center">
								<div>
									<div class="icon icon-xs text-sm icon-shape bg-danger-subtle text-danger rounded-circle me-3">
										<i class="bi bi-check text-md"></i>
									</div>
								</div>
								<div>
									<span class="text-heading fw-semibold mb-0">{{ descriptionDateTo() }}</span>
								</div>
							</div>
						</li>
						<li class="pt-3">
							<div class="d-flex align-items-center">
								<div>
									<div class="icon icon-xs text-sm icon-shape bg-danger-subtle text-danger rounded-circle me-3">
										<i class="bi bi-check text-md"></i>
									</div>
								</div>
								<div>
									<span class="text-heading fw-semibold mb-0">{{ descriptionDays() }}</span>
								</div>
							</div>
						</li>
						<li class="pt-3" v-if="discount.type == 'PRODUCTS'">
							<div class="d-flex align-items-center">
								<div>
									<div class="icon icon-xs text-sm icon-shape bg-danger-subtle text-danger rounded-circle me-3">
										<i class="bi bi-check text-md"></i>
									</div>
								</div>
								<div>
									<span class="text-heading fw-semibold mb-0">Combiná con {{ descriptionProductCombination() }}</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import _ from 'lodash';

	const DAYS = {
		1: 'Lunes',
		2: 'Martes',
		3: 'Miércoles',
		4: 'Jueves',
		5: 'Viernes',
		6: 'Sábado',
		7: 'Domingo'
	}

	const PRODUCT_COMBINATION = {
		'SAME': 'productos iguales',
		'VALUE': 'productos del mismo valor',
		'ANY': 'otros productos'
	}

	export default {
		props: {
			discount: Object
		},
		setup(props) {
			const descriptionDateTo = () => {
				if(!props.discount.to) return 'Sin fecha de caducidad';
				else return `Válida hasta el ${moment(props.discount.to).format('D [de] MMMM, HH:mm[Hs]')}`;
			}

			const descriptionDays = () => {
				if(!props.discount.days) return 'Todos los días de la semana';
				var days = _.map(props.discount.days, function(day) { return DAYS[day]; });
				return `Días: ${days.join(', ')}`;
			}

			const descriptionProductCombination = () => {
				if(props.discount.products_combination in PRODUCT_COMBINATION) return PRODUCT_COMBINATION[props.discount.products_combination];
				else return '';
			}

			return { descriptionDateTo, descriptionDays, descriptionProductCombination };
		}
	}
</script>
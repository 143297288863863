<template>
	<div class="btn-location position-relative align-items-center d-flex hstack gap-2 text-xs fw-bold rounded-pill border border-2" v-if="isDefined">
		<span class="badge btn-description rounded-pill text-uppercase text-xs" v-if="address.description">{{ address.description }}</span>
		<div class="d-block text-truncate">
			<router-link :to="{name: 'location.create'}" class="stretched-link text-sm text-inherit">{{ complete }}</router-link>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';

	export default {
		props: {
			styles: Object
		},
		setup(props) {
			const isDefined = computed(() => store.getters['location/isDefined']);
			const complete = computed(() => store.getters['location/complete']);
			const address = computed(() => store.getters['location/address']);

			const background = computed(() => {
				if(props.styles?.header?.background) return props.styles.header.background;
				else return '#fcfcfc';
			});

			const color = computed(() => {
				if(props.styles?.header?.color) return props.styles.header.color;
				else return '#16192c';
			});

			return { isDefined, complete, address, background, color };
		}
	}
</script>

<style scoped>
	.btn-location {
		padding: 0.415rem !important;
		border-color: v-bind(color) !important;
		color: v-bind(color);
	}

	.btn-description {
		background-color: v-bind(color);
		color: v-bind(background);
	}
</style>
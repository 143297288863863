<template>
	<teleport to="body">
		<div class="modal" id="assistant-suggesteds-stores-modal" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content" v-if="store">
					<div class="modal-header p-0 border-bottom-0" v-if="!!store.cover">
						<div class="container bg-cover store-cover rounded-top position-relative p-3" :style="{'background-image': `url(${store.cover_small_url})`}" v-if="!imageSkeletor && !!store.cover_small_url"></div>
						<Skeletor height="230" class="container bg-cover store-cover rounded-top" v-if="imageSkeletor" />
					</div>
					<div class="modal-body hstack gap-3">
						<div class="avatar avatar-lg rounded" v-if="!!store.logo">
							<img :src="store.logo_url" alt="logo" @load="logoReady = true" v-show="logoReady">
							<Skeletor width="100%" height="100%" class="rounded" v-show="!logoReady" />
						</div>
						<div class="overflow-hidden">
							<h3 class="text-truncate">{{ store.name }}</h3>
							<div class="text-md text-truncate">{{ store.description }}</div>
						</div>
					</div>
					<div class="modal-footer d-flex justify-content-between">
						<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Cerrar</button>
						<router-link :to="{ name: 'store.home', params: { slug: store.slug } }" class="btn btn-sm btn-primary">Ir a la tienda</router-link>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, watch, onMounted } from 'vue';
	import { Modal } from 'bootstrap';

	export default {
		emits: ['selectStore'],
		props: {
			store: Object
		},
		setup(props, { emit }) {
			const modalStore = ref(null);
			onMounted(() => {
				modalStore.value = new Modal(document.getElementById('assistant-suggesteds-stores-modal'));
				document.getElementById('assistant-suggesteds-stores-modal').addEventListener('hidden.bs.modal', () => {
					emit('selectStore', null);
				});
			});

			const logoReady = ref(false);
			const imageSkeletor = ref(true);
			watch(() => props.store, (newValue) => {
				if(newValue) {
					modalStore.value.show();
					imageSkeletor.value = !!props.store.cover_small_url;

					if(props.store.cover_small_url != null) {
						var loadImage = new Image();
						loadImage.onload = function() { imageSkeletor.value = false; };
						loadImage.src = props.store.cover_small_url;
					}
				}
			});

			return { logoReady, imageSkeletor };
		}
	}
</script>

<style scoped>
	.store-cover {
		height: 230px;
		background-position: center;
	}

	.avatar {
		background-size: cover !important;
		background-position: center !important;
	}

	.badge {
		padding: .24rem .5rem;
	}
</style>
import Repository from "./Repository";

const resource = "/tokens";

export default {
	create(payload) {
		return Repository.post(`${resource}`, payload);
	},
	socialClient(provider, data) {
		return Repository.post(`${resource}/social/${provider}/client`, data);
	},
	socialServer(provider, data) {
		return Repository.post(`${resource}/social/${provider}/server`, data);
	},
	otpExchange(payload) {
		return Repository.post(`${resource}/otp`, payload);
	}
}
<template>
	<teleport to="body">
		<div class="modal" id="stores-marketing-items-modal" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content" v-if="item">
					<div class="modal-header p-0 border-bottom-0" v-if="!!item.image_md_url">
						<div class="container bg-cover store-cover rounded-top position-relative p-3" :style="{'background-image': `url(${item.image_md_url})`}" v-if="!imageSkeletor && !!item.image_md_url"></div>
						<Skeletor height="230" class="container bg-cover store-cover rounded-top" v-if="imageSkeletor" />
					</div>
					<div class="modal-body">
						<div id="item-description">
							<h3 class="h3 mb-2">{{ item.name }}</h3>
							<div class="d-flex justify-content-between">
								<h3 class="h3 text-nowrap">{{ vueNumberFormat(priceReal, { prefix: `${current_store.personalize.currency.symbol ?? '$'} `, precision: current_store.personalize.currency.decimals ?? 2 }) }}</h3>
								<div class="ms-3" v-if="hasDiscount">
									<small class="text-muted me-4" v-if="item.price != priceReal"><del>{{ vueNumberFormat(item.price, { prefix: `${current_store.personalize.currency.symbol ?? '$'} `, precision: current_store.personalize.currency.decimals ?? 2 }) }}</del></small>
									<div class="badge rounded-pill bg-danger text-end">{{ discountDescription }} <i class="bi bi-info-circle-fill ms-1"></i></div>
								</div>
							</div>
						</div>
						<p class="text-muted pt-3 text-break text-white-space">{{ item.description }}</p>
						<store-card-small :store="current_store" class="mt-7" />
					</div>
					<div class="modal-footer d-flex justify-content-between">
						<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Cancelar</button>
						<router-link :to="{ name: 'store.item', params: { slug: current_store.slug, id: item.id } }" class="btn btn-sm btn-primary">Ir al producto</router-link>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, computed, watch, onMounted } from 'vue';
	import store from '@/store';
	import { Modal } from 'bootstrap';
	import _ from 'lodash';

	export default {
		emits: ['select'],
		props: {
			item: Object
		},
		setup(props, { emit }) {
			const stores = computed(() => store.getters['stores/list']);
			const current_store = computed(() => _.find(stores.value, { id: props.item?.store_id }));

			const hasDiscount = computed(() => { return !!props.item.discount; });
			const discountAmount = computed(() => {
				if(!hasDiscount.value) return 0;

				var amount = 0;
				switch(props.item.discount.type) {
					case 'PERCENTAGE':
						amount = Math.round(props.item.price * (props.item.discount.value / 100) * 100) / 100;
						break;
					case 'PRODUCTS':
						var factor = Math.trunc(1 / props.item.discount.products_from);
						var free = factor * (props.item.discount.products_from - props.item.discount.products_to);

						amount = free * props.item.price;
						break;
				}

				return amount;
			});

			const discountDescription = computed(() => {
				var description = null;

				if(hasDiscount.value) {
					switch(props.item.discount.type) {
						case 'PERCENTAGE':
							description = `${props.item.discount.value}% OFF`;
							break;
						case 'PRODUCTS':
							description = `Lleva ${props.item.discount.products_from} paga ${props.item.discount.products_to}`;
							break;
					}
				}

				return description;
			});

			const priceReal = computed(() => { return props.item.price - discountAmount.value; });

			const modalItem = ref(null);
			onMounted(() => {
				modalItem.value = new Modal(document.getElementById('stores-marketing-items-modal'));
				document.getElementById('stores-marketing-items-modal').addEventListener('hidden.bs.modal', () => {
					emit('select', null);
				});
			});

			const imageSkeletor = ref(true);
			watch(() => props.item, (newValue) => {
				if(newValue) {
					modalItem.value.show();
					imageSkeletor.value = !!props.item.image_md_url;

					if(props.item.image_md_url != null) {
						var loadImage = new Image();
						loadImage.onload = function() { imageSkeletor.value = false; };
						loadImage.src = props.item.image_md_url;
					}
				}
			});

			return { current_store, imageSkeletor, hasDiscount, discountDescription, priceReal };
		}
	}
</script>

<style scoped>
	.store-cover {
		height: 230px;
		background-position: center;
	}

	.avatar {
		background-size: cover !important;
		background-position: center !important;
	}

	.badge {
		padding: .24rem .5rem;
	}
</style>
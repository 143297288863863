import router from '@/router';

export default {
	namespaced: true,
	state: null,
	getters: {
		isDefined(state) {
			return !!state?.id;
		},
		data(state) {
			return state;
		}
	},
	actions: {
		init({ commit }) {
			try {
				commit('setData', JSON.parse(document.body.getAttribute('data-microsite')));
			}catch(e) {
				router.push({ name: 'not_site' });
			}
		}
	},
	mutations: {
		setData(state, data) {
			Object.assign(state, data);
		}
	}
}
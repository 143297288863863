import Repository from "./Repository";

const resource = "/aiChat";

export default {
	index(payload) {
		return Repository.get(`${resource}`, payload);
	},
	create(payload) {
		return Repository.post(`${resource}`, payload);
	},
	message(id, payload) {
		return Repository.post(`${resource}/${id}/message`, payload);
	}
}
<template>
	<div class="col-12 col-md-6 col-lg-4">
		<div class="hstack gap-3">
			<div class="position-relative">
				<vue-flip v-model="showCount" :width="'85px'" :height="'85px'" :transition="'0.2s'">
					<template v-slot:front>
						<button type="button" class="w-100 stretched-link p-0 bg-transparent" @click="addToCart">
							<img :src="image" class="img-fluid rounded" alt="image" v-show="imageReady" @load="imageReady = true" />
							<Skeletor width="85" height="85" class="img-fluid rounded" v-show="!imageReady" />
						</button>
					</template>
					<template v-slot:back>
						<div class="w-100 h-100 rounded bg-secondary bg-opacity-10 d-flex align-items-center justify-content-center gap-1">
							<button type="button" class="btn btn-danger btn-square btn-sm" @click="sub">{{ orderCount }}</button>
							<button type="button" class="btn btn-dark btn-square btn-sm" @click="addToCart"><i class="bi bi-plus"></i></button>
						</div>
					</template>
				</vue-flip>
			</div>
			<div class="overflow-hidden position-relative">
				<a href="javascript:void(0)" @click="view" class="text-md fw-semibold link-dark stretched-link d-block text-truncate">{{ item.name }}</a>
				<span class="d-block text-sm text-muted text-truncate">{{ item.description ?? 'Sin descripción' }}</span>
				<div class="d-flex align-items-center">
					<span class="text-md text-dark fw-semibold">{{ vueNumberFormat(priceFinal, $personalizer.currency.value) }}</span>
					<span class="ms-3 badge rounded-pill bg-danger" v-if="hasDiscount">{{ discountDescription }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, computed } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import moment from 'moment';
	import _ from 'lodash';
	import { VueFlip } from 'vue-flip';
	import composableStore from '@/composables/store';
	import composableCheckout from '@/composables/checkout';

	export default {
		components: { VueFlip },
		props: {
			item: Object
		},
		setup(props) {
			const { showStatus } = composableStore();
			const { items, canOrder, addItem } = composableCheckout();
			const view = () => {
				if(!canOrder.value) {
					showStatus();
					return;
				}

				router.push({name: 'store.item', params: {id: props.item.id}});
			}

			const imageReady = ref(false);
			const image = computed(() => {
				if(props.item.image_sm) return props.item.image_sm;
				return require('@/assets/img/icons/no-image.jpg');
			});

			const catalogType = computed(() => (store.getters['code/isDefined']) ? store.getters['code/type'] : 'ONLINE');
			const orderCount = computed(() => { return _.sumBy(items.value, function(row) { return (row.id == props.item.id) ? row.quantity : 0; }); });
			const showCount = computed(() => { return (orderCount.value > 0); });

			const discount = computed(() => {
				return _.find(store.getters['store/discounts'], (data) => {
					if(_.indexOf(data.catalogs, catalogType.value) === -1) return false;
					if(data.from && moment(data.from).isAfter(moment())) return false;
					if(data.to && moment(data.to).isBefore(moment())) return false;
					if(data.days && _.indexOf(data.days, moment().day()) === -1) return false;

					return (data.target == null || _.indexOf(data.target, props.item.id) != -1);
				});
			});
			const hasDiscount = computed(() => { return !!discount.value; });
			const discountPercentage = computed(() => { return (hasDiscount.value && discount.value.type == 'PERCENTAGE') ? discount.value.value : 0; });
			const priceFinal = computed(() => { return props.item.price - (props.item.price * (discountPercentage.value / 100)); });

			const discountDescription = computed(() => {
				var description = null;

				if(hasDiscount.value) {
					switch(discount.value.type) {
						case 'PERCENTAGE':
							description = `${discount.value.value}% OFF`;
							break;
						case 'PRODUCTS':
							description = `Lleva ${discount.value.products_from} paga ${discount.value.products_to}`;
							break;
					}
				}

				return description;
			});

			const addToCart = () => {
				if(!addItem(props.item, 1, [], null, false)) view();
			}

			const sub = () => store.dispatch('cart/subItem', props.item.id);

			return { imageReady, image, view, hasDiscount, discount, discountDescription, priceFinal, orderCount, showCount, addToCart, sub };
		}
	}
</script>

<style scoped>
	.item-body {
		max-width: 100%;
	}

	.item-body-image {
		max-width: calc(100% - 6rem) !important;
	}

	.badge {
		padding: .12rem .5rem;
		font-size: 0.68em;
	}
</style>
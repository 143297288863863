<template>
	<router-link :to="{name: 'store.home', params: {slug: store.slug}}" class="hstack gap-3 text-limit">
		<div class="avatar rounded" v-if="!isDefined || store.logo_url">
			<img :src="store.logo_url" alt="logo" @load="logoReady = true" v-show="logoReady">
			<Skeletor width="100%" height="100%" class="rounded" v-show="!logoReady" />
		</div>
		<div class="overflow-hidden">
			<div class="fw-semibold h4 text-truncate">{{ store.name }}</div>
			<Skeletor class="fw-semibold h4" v-show="!isDefined" />
			<div class="text-sm text-dark text-truncate">
				{{ description() }}
				<span class="ms-1 fw-semibold" v-if="store.address.hasOwnProperty('distance')">{{ distanceDescription() }}</span>
			</div>
		</div>
	</router-link>
</template>

<script>
	import { ref, computed } from 'vue';

	export default {
		props: {
			store: Object,
			subtitle: String
		},
		setup(props) {
			const isDefined = computed(() => !!props.store.id);
			const logoReady = ref(false);

			const description = () => {
				if(props.subtitle) return props.subtitle;
				else return props.store.address.complete;
			}

			const distanceDescription = () => {
				var description = null;

				if(props.store && props.store.address.distance) {
					var distance = Math.floor(props.store.address.distance);

					if(distance <= 0) {
						description = `< 1km`;
					}else if(distance > 99) {
						description = `+99km`;
					}else{
						description = `${distance}km`;
					}
				}

				return description;
			}

			return { isDefined, logoReady, description, distanceDescription };
		}
	}
</script>

<style scoped>
	.avatar {
		min-width: 2.875rem !important;
		min-height: 2.875rem !important;
	}
</style>
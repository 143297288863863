<template>
	<div class="list-group-item">
		<div class="row align-items-center">
			<div class="col-8">
				<h6 class="text-sm mb-0">{{ additional.name }}</h6>
				<small class="text-muted" v-if="additional.price > 0">+ {{ vueNumberFormat(additional.price, $personalizer.currency.value) }}</small>
				<small class="text-muted" v-if="additional.price == 0">Sin costo adicional</small>
			</div>
			<div class="col-4 text-end">
				<component :is="input" :min="0" :remaining="remaining" :disabled="disabled" @updated="updated" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			additional: Object,
			disabled: Boolean,
			remaining: Number
		},
		setup(props, {emit}) {
			const input = (props.additional.max === 1) ? 'catalog-additional-checkbox' : 'catalog-additional-spinbox';
			const updated = (value) => emit('updated', {id: props.additional.id, name: props.additional.name, unit_price: props.additional.price, quantity: value});

			return { input, updated };
		}
	}
</script>

<style scoped>
	.list-group-item {
		padding: .5rem 0rem !important;
	}
</style>
<template>
	<user-status-avatar :user="user" />
	<user-status-menu v-if="hasUser" :user="user" />
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';

	export default {
		setup() {
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);

			return { hasUser, user };
		}
	}
</script>
<template>
	<a href="javascript:void(0);" class="stretched-link position-relative" @click="select">
		<div class="avatar avatar-xl avatar-background border border-2 border-dark rounded-3" :style="{'background-image': `url(${item.image_sm_url})`}" v-if="imageReady"></div>
		<Skeletor height="100%" class="avatar avatar-xl avatar-background border border-2 border-dark rounded-3 avatar-background" v-if="!imageReady" />
		<small class="badge rounded-pill bg-danger position-absolute bottom-0 end-0 m-2 fw-bolder" v-if="hasDiscount">{{ discountDescription }}</small>
	</a>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';

	export default {
		emits: ['select'],
		props: {
			item: Object
		},
		setup(props, { emit }) {
			const imageReady = ref(false);

			onMounted(() => {
				var loadImage = new Image();
				loadImage.src = props.item.image_sm_url;
				loadImage.onload = function() { imageReady.value = true; };
			});

			const hasDiscount = computed(() => { return !!props.item.discount; });
			const discountDescription = computed(() => {
				var description = null;

				if(hasDiscount.value) {
					switch(props.item.discount.type) {
						case 'PERCENTAGE':
							description = `-${props.item.discount.value}%`;
							break;
						case 'PRODUCTS':
							description = `${props.item.discount.products_from}x${props.item.discount.products_to}`;
							break;
					}
				}

				return description;
			});

			const select = () => emit('select', props.item);

			return { imageReady, select, hasDiscount, discountDescription };
		}
	}
</script>

<style scoped>
	.avatar {
		background-size: cover !important;
		background-position: center !important;
	}

	.badge {
		padding: .07rem .4rem;
		font-size: 0.7em;
	}
</style>
<template>
	<hr class="my-0" v-if="index" />
	<div class="d-flex justify-content-between p-4">
		<div class="form-check position-relative">
			<input type="radio" v-model="option" value="pos-mobile" class="form-check-input" :disabled="loading">
			<div class="form-check-label text-dark d-block ms-2">
				<a href="javascript:void(0)" class="stretched-link text-dark" @click="select">POS Móvil</a>
				<small class="d-block lh-sm" v-if="option == 'pos-mobile' && payment_method.description">{{ payment_method.description }}</small>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';

	export default {
		emits: ['select'],
		props: {
			loading: Boolean,
			payment_method: Object,
			index: Number
		},
		setup(props, { emit }) {
			const payment = computed(() => store.getters['cart/payment']);
			const option = computed(() => {
				if(!payment.value) return null;
				else return payment.value.option;
			});

			const description = computed(() => {
				var description = null;

				switch(payment.value.type) {
					case 'exact':
						description = `Paga <b>con importe exacto</b>`;
						break;
					case 'other':
						description = `Paga <b>con $ ${payment.value.amount}</b>`;
						break;
					default:
						description = `Alias: ramiro.llanos (Titular: Ramiro Llanos).`;
						break;
				}

				return description;
			});

			const select = () => {
				emit('select', { option: 'pos-mobile' });
			}

			return { payment, description, option, select };
		}
	}
</script>

<style scoped>
	.form-check-label {
		font-size: 1rem !important;
		font-weight: normal !important;
	}
</style>
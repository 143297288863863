<template>
	<div class="card border border-2 border-dark h-100">
		<!-- Card Body -->
		<div class="card-body p-3 hstack gap-3">
			<div v-if="store.logo">
				<a href="#" class="avatar rounded">
					<img :src="store.logo_url" alt="logo" v-show="imageReady" @load="imageReady = true" />
					<Skeletor width="100%" height="100%" class="img-fluid rounded" v-show="!imageReady" />
				</a>
			</div>
			<div class="overflow-hidden">
				<router-link :to="{name: 'store.home', params: {slug: store.slug}}" class="lh-base h5 fw-semibold link-dark stretched-link d-block text-truncate">{{ store.name }}</router-link>
				<div class="lh-wider text-sm text-muted info-extra text-truncate" v-if="showAddress">
					{{ store.address.small }}
					<span class="fw-semibold" v-if="store.address.hasOwnProperty('distance')">
						<span class="mx-1">•</span>
						{{ distanceDescription() }}
					</span>
				</div>
				<div class="lh-wider text-sm info-extra text-truncate" v-html="infoExtra()"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, computed } from 'vue';
	import _ from 'lodash';

	export default {
		props: {
			store: Object
		},
		setup(props) {
			const imageReady = ref(false);

			const showAddress = computed(() => {
				return (_.indexOf(['store', 'market'], props.store.type) !== -1);
			});

			const infoExtra = () => {
				if(props.store.type == 'market') return '<span class="text-dark">Mercado</span>';
				if(props.store.type == 'headquarters') return '<span class="text-dark">Casa matriz</span>';
				if(props.store.open) return `<span class="text-success">${(props.store.delay_show == 0) ? 'Sin demora' : `${props.store.delay_show} min`}</span>`;
				else return '<span class="text-danger">Cerrado</span>';
			}

			const distanceDescription = () => {
				var description = null;

				if(props.store && props.store.address.distance) {
					var distance = Math.floor(props.store.address.distance);

					if(distance <= 0) {
						description = `< 1km`;
					}else if(distance > 99) {
						description = `+99km`;
					}else{
						description = `${distance}km`;
					}
				}

				return description;
			}

			return { imageReady, showAddress, infoExtra, distanceDescription };
		}
	}
</script>

<style scoped>
	.avatar {
		width: 4rem !important;
		height: 4rem !important;
	}

	.border-lighter {
		border-color: #f5f9ff !important;
	}

	.info-extra {
		line-height: 1.3rem;
	}
</style>
<template>
	<section class="py-20">
		<div class="container mw-screen-xl">
			<div class="row justify-content-center align-items-center">
				<div class="col-12 text-center">
					<!-- Heading -->
					<div class="ls-tight fw-bolder h2 mb-5">
						<div class="spinner-grow text-dark" style="width: 3rem; height: 3rem;" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
					<div class="ls-tight fw-bolder h3">{{ text }}</div>
					<!-- Text -->
					<p class="lead text-muted">Aguarde un momento</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		props: {
			text: String
		}
	}
</script>
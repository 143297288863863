<template>
	<div id="menu-mercadopago-online" class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header container container-offcanvas">
			<h5 class="offcanvas-title" id="offcanvasBottomLabel">
				<span>Ingresá los datos de tu tarjeta</span>
				<span class="d-block text-sm fw-semibold text-muted">Con la seguridad de <b>MercadoPago</b>.</span>
			</h5>
			<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body container container-offcanvas pt-0">
			<form id="form-card">
				<div class="d-none">
					<select id="issuer"></select>
					<select id="installments"></select>
				</div>
				<div class="form-group-stacked mb-5">
					<img src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/online/575X40.jpg" title="Mercado Pago - Medios de pago" alt="Mercado Pago - Medios de pago" class="d-none d-md-block" style="width: 100%;" />
					<img src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/online/468X60.jpg" title="Mercado Pago - Medios de pago" alt="Mercado Pago - Medios de pago" class="d-block d-md-none" style="width: 100%;" />
				</div>
				<div class="form-group-stacked mb-7">
					<div class="row g-3">
						<div class="col-9">
							<div class="input-group">
								<input type="tel" id="card-number" v-model="cardNumber" class="form-control" placeholder="Número de tarjeta" v-maska="{mask: '#### #### #### ####'}">
								<span class="input-group-text d-none">
									<img src="https://www.mercadopago.com/org-img/MP3/API/logos/visa.gif" class="img-card" />
								</span>
							</div>
						</div>
						<div class="col-3">
							<input type="number" id="security-code" v-model="securityCode" class="form-control" placeholder="CVV" v-maska="{mask: '####'}">
						</div>
						<div class="col-6">
							<select id="card-expiration-month" v-model="cardExpirationMonth" class="form-select">
								<option :value="null" selected disabled>Mes de vencimiento</option>
								<option :value="`0${index}`.slice(-2)" v-for="index in 12" v-bind:key="index">{{ `0${index}`.slice(-2) }}</option>
							</select>
						</div>
						<div class="col-6">
							<select id="card-expiration-year" v-model="cardExpirationYear" class="form-select">
								<option :value="null" selected disabled>Año de vencimiento</option>
								<option :value="getYear(index)" v-for="index in 10" v-bind:key="index">{{ getYear(index) }}</option>
							</select>
						</div>
						<div class="col-12">
							<input type="text" id="cardholder-name" v-model="cardholderName" class="form-control" placeholder="Titular de la tarjeta">
						</div>
						<div class="col-4">
							<select id="identification-type" v-model="identificationType" class="form-select"></select>
						</div>
						<div class="col-8">
							<input type="tel" id="identification-number" v-model="identificationNumber" class="form-control" placeholder="Documento de identidad">
						</div>
					</div>
				</div>
				<div class="d-grid gap-2 d-md-flex justify-content-md-end">
					<button type="button" class="btn btn-sm btn-neutral me-md-2" data-bs-dismiss="offcanvas">Cancelar</button>
					<button type="submit" class="btn btn-sm btn-dark" :disabled="submitting">Pagar {{ vueNumberFormat(total, $personalizer.currency.value) }}</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import { ref, computed, watch, onMounted } from 'vue';
	import store from '@/store';
	import composableMercadopago from '@/composables/payments/mercadopago';
	import moment from 'moment';

	export default {
		emits: ['setCard'],
		props: {
			constraints: Object
		},
		setup(props, { emit }) {
			const total = computed(() => store.getters['cart/total']);
			const cardNumber = ref(null);
			const securityCode = ref(null);
			const cardExpirationMonth = ref(null);
			const cardExpirationYear = ref(null);
			const cardholderName = ref(null);
			const identificationType = ref('DNI');
			const identificationNumber = ref(null);

			const getYear = (yearAdd) => {
				return moment().add((yearAdd - 1), 'years').format('YYYY');
			}

			const submit = async (data) => {
				emit('setCard', {
					option: 'mercadopago-online',
					gateway: 'mercadopago',
					id: data.id,
					installments: data.installments,
					cardNumber: cardNumber.value.slice(-4),
					token: data.token
				});

				reset();
			}

			const reset = () => {
				cardNumber.value = null;
				securityCode.value = null;
				cardExpirationMonth.value = null;
				cardExpirationYear.value = null;
				cardholderName.value = null;
				identificationType.value = 'DNI';
				identificationNumber.value = null;
			}

			const { init, submitting } = composableMercadopago(submit);
			const checkContraints = () => {
				if(props.constraints) init(props.constraints.config, total.value);
			}

			onMounted(() => {
				document.getElementById('menu-mercadopago-online').addEventListener('shown.bs.offcanvas', checkContraints);
			});
			watch(() => props.constraints, checkContraints);

			return { total, cardNumber, securityCode, cardExpirationMonth, cardExpirationYear, cardholderName, identificationType, identificationNumber, getYear, submitting };
		}
	}
</script>

<style scoped>
	.img-card {
		max-height: 20px;
	}
</style>
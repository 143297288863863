<template>
	<div class="d-block">
		<div class="d-flex">
			<div class="me-3">
				<div class="avatar avatar-sm rounded-circle" v-show="imageReady">
					<img :src="logo" @load="imageReady = true" alt="Avatar">
				</div>
				<Skeletor width="36" height="36" class="avatar avatar-sm rounded-circle" v-show="!imageReady" />
			</div>
			<div class="vstack gap-2">
				<!-- Name -->
				<div class="d-flex align-items-center">
					<span class="d-block lh-1 text-sm fw-semibold text-heading">{{ name }}</span>
					<span class="d-block lh-1 mx-3 text-xs text-muted">{{ createdAtForHummans }}</span>
				</div>
				<!-- Text -->
				<div class="d-flex w-md-75">
					<div class="px-3 py-2 rounded-bottom-2 rounded-top-end-2 text-sm bg-success text-white text-break text-white-space">{{ message.message }}</div>
				</div>
			</div>
		</div>
		<assistant-message-suggested class="mt-2" :suggested="message.suggested_objects" @selectStore="selectStore" @selectItem="selectItem" v-if="Object.values(message.suggested_objects).length > 0" />
	</div>
</template>

<script>
	import { ref, computed } from 'vue';
	import moment from 'moment';

	export default {
		emits: ['selectStore', 'selectItem'],
		props: {
			type: String,
			store: Object,
			message: Object
		},
		setup(props, { emit }) {
			const createdAtForHummans = computed(() => moment(props.message.created_at).format('hh:mm A'));
			const imageReady = ref(false);
			const selectStore = (data) => emit('selectStore', data);
			const selectItem = (data) => emit('selectItem', data);

			const logo = computed(() => {
				if(props.store?.logo_url) return props.store.logo_url;
				return require('@/assets/img/ai/head.jpg');
			});

			const name = computed(() => {
				if(props.type == 'general') return 'Asistente virtual';
				else return props.store.name;
			});

			return { createdAtForHummans, imageReady, selectStore, selectItem, logo, name };
		}
	}
</script>
<template>
	<div class="d-flex justify-content-between align-items-center">
		<div class="hstack gap-3">
			<div class="avatar rounded" v-if="order.store.logo">
				<img :src="order.store.logo_url" alt="logo">
			</div>
			<div>
				<router-link :to="{name: 'order.view', params: {id: order.id}}" class="stretched-link h5 mb-0">{{ order.store.name }}</router-link>
				<span class="d-block text-sm text-muted">
					<span class="me-2">{{ getCreatedAtFormatted }}</span>
					<span class="fw-bold d-inline-block">{{ vueNumberFormat(order.total, { prefix: `${order.store.personalize.currency.symbol ?? '$'} `, precision: order.store.personalize.currency.decimals ?? 2 }) }}</span>
				</span>
			</div>
		</div>
		<div class="d-flex align-items-center text-end">
			<span class="badge rounded-pill" :class="getStatusClass">{{ getStatusTitle }}</span>
		</div>
	</div>
</template>

<script>
	import { ref } from 'vue';
	import composableOrderDetail from '@/composables/orderDetail';

	export default {
		props: {
			order: Object
		},
		setup(props) {
			const orderRef = ref(props.order);
			const { getStatusTitle, getStatusClass, getCreatedAtFormatted } = composableOrderDetail(orderRef);

			return { getStatusTitle, getStatusClass, getCreatedAtFormatted };
		}
	}
</script>
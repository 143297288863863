<template>
	<h5 class="mb-4">¿Cómo lo querés recibir?</h5>
	<Skeletor height="100" class="card bg-white shadow-1 border rounded-2" v-if="!displayOptions" />
	<div class="card bg-white shadow-1 border rounded-2" v-if="displayOptions">
		<div class="d-flex justify-content-between p-4" v-if="allowOption('LOCAL') && hasCode">
			<div class="form-check position-relative">
				<input type="radio" v-model="option" value="LOCAL" class="form-check-input" :disabled="loading">
				<div class="form-check-label text-dark d-block ms-2">
					<a href="javascript:void(0)" class="stretched-link text-dark" @click="select('LOCAL')">Local</a>
					<small class="d-block lh-sm">En <b>{{ codeDescription }}</b></small>
				</div>
			</div>
			<a href="javascript:void(0)" class="text-danger" @click="leave">Ya no estoy aquí</a>
		</div>
		<div v-if="!(allowOption('LOCAL') && hasCode)">
			<div class="d-flex justify-content-between p-4" v-if="!allowOption('TAKEAWAY') && !allowOption('DELIVERY')">
				<div class="form-check position-relative ps-0 text-warning">Sólo es posible realizar pedidos en el local.</div>
			</div>
			<hr class="my-0" v-if="allowOption('LOCAL') && hasCode && allowOption('TAKEAWAY')" />
			<div class="d-flex justify-content-between p-4" v-if="allowOption('TAKEAWAY')">
				<div class="form-check position-relative">
					<input type="radio" v-model="option" value="TAKEAWAY" class="form-check-input" :disabled="loading">
					<div class="form-check-label text-dark d-block ms-2">
						<a href="javascript:void(0)" class="stretched-link text-dark" @click="select('TAKEAWAY')">Take away</a>
						<small class="d-block lh-sm" v-if="option == 'TAKEAWAY'">Retirar en <b>{{ store.address.complete }}</b>.</small>
					</div>
				</div>
			</div>
			<hr class="my-0" v-if="allowOption('TAKEAWAY') && allowOption('DELIVERY')" />
			<div class="d-flex justify-content-between p-4" v-if="allowOption('DELIVERY')">
				<div class="form-check position-relative">
					<input type="radio" v-model="option" value="DELIVERY" class="form-check-input" :disabled="loading">
					<div class="form-check-label text-dark d-block ms-2">
						<a href="javascript:void(0)" class="stretched-link text-dark" @click="selectDelivery">Delivery</a>
						<small class="d-block lh-sm" v-if="option == 'DELIVERY'">Recibir en <b v-html="locationDescription"></b>.</small>
						<small class="d-block lh-sm text-muted" v-if="option == 'DELIVERY' && raining"><i class="bi bi-cloud-drizzle"></i> Tu pedido puede sufrir demoras debido a las condiciones climáticas.</small>
					</div>
				</div>
				<a href="javascript:void(0)" class="text-dark" v-if="option == 'DELIVERY'" @click="locationCreate">Editar</a>
			</div>
		</div>
	</div>

	<div class="modal" id="modal-location-rejected" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
		<div class="modal-dialog modal-md modal-dialog-centered">
			<div class="modal-content shadow-4">
				<div class="modal-body text-center">
					<h4 class="mb-1 text-danger">El delivery no se encuentra disponible para tu ubicación</h4>
					<p class="text-sm text-muted fw-semibold" v-html="locationDescription"></p>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Cancelar</button>
					<button type="button" class="btn btn-sm btn-dark" @click="locationAction">Mis direcciones</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import StoreRepository from '@/repositories/StoreRepository';
	import composableForm from '@/composables/form';
	import { useToast } from 'vue-toastification';
	import { Modal } from 'bootstrap';
	import _ from 'lodash';

	export default {
		emits: ['updateLoading'],
		props: {
			loading: Boolean,
			displayOptions: Boolean,
			store: Object,
			services: Object
		},
		setup(props, { emit }) {
			const { catchError } = composableForm();
			const toast = useToast();
			const hasCode = computed(() => store.getters['code/isDefined']);
			const codeDescription = computed(() => {
				if(hasCode.value) return store.getters['code/info'].description;
				else return null;
			});
			const hasLocation = computed(() => store.getters['location/isDefined']);
			const locationDescription = computed(() => {
				let description = [];
				if(store.getters['location/address'].description) return `${store.getters['location/complete']} (${store.getters['location/address'].description})`;
				else return description.push(store.getters['location/complete']);
			});
			const option = computed(() => {
				if(!store.getters['cart/type']) return null;
				else return store.getters['cart/type'].option;
			});
			const raining = ref(false);

			const allowOption = (type) => {
				var service = _.find(props.services, (row) => { return row.type == type });
				return (service != undefined && service.status == 'available');
			}

			const locationCreate = () => {
				router.push({ name: 'location.create' });
			}

			const select = (option) => {
				if(props.loading) return;

				emit('updateLoading', true);
				store.dispatch('cart/setType', { option: option });
				emit('updateLoading', false);
			}

			//Modal de ubicación rechazada
			const modal = ref(null);
			onMounted(() => modal.value = new Modal(document.getElementById(`modal-location-rejected`)));
			const locationDialog = () => modal.value.show();
			const locationAction = () => {
				modal.value.hide();
				router.push({ name: 'location.index' });
			}

			const selectDelivery = async () => {
				if(props.loading) return;
				if(!hasLocation.value) return locationCreate();

				var payload = null;
				emit('updateLoading', true);

				await StoreRepository.rateDelivery(props.store.slug, { geolocation: store.getters['location/geolocation'], amount: store.getters['cart/subtotal'] })
					.then((response) => {
						if(response.data.status == 'unavailable') {
							if(response.data.minimum != 0) toast.error(`El pedido mínimo para envio a tu zona es de $${response.data.minimum.toString()}`);
							else locationDialog();
						}else{
							payload = { option: 'DELIVERY', amount: response.data.amount };
						}

						raining.value = response.data.raining;
					})
					.catch((e) => catchError(e, true));

				if(payload) store.dispatch('cart/setType', payload);
				emit('updateLoading', false);
			}

			const leave = () => store.dispatch('code/setCode', null);

			return { hasCode, codeDescription, locationDescription, allowOption, option, raining, select, selectDelivery, locationCreate, leave, locationAction };
		}
	}
</script>

<style scoped>
	.form-check-label {
		font-size: 1rem !important;
		font-weight: normal !important;
	}
</style>

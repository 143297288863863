import LocationRepository from '@/repositories/LocationRepository';

export default {
	namespaced: true,
	state: {
		info: null
	},
	getters: {
		isDefined(state) {
			return !!state.info;
		},
		address(state) {
			return state.info;
		},
		complete(state) {
			if(!state.info) return 'Sin definir';

			var complete = [];

			if(state.info.route) complete.push(state.info.route);
			if(state.info.street_number) complete.push(`Nro. ${state.info.street_number}`);
			if(state.info.division) complete.push(`(${state.info.division})`);

			return (complete.length > 0) ? complete.join(' ') : 'Sin descripción';
		},
		geolocation(state) {
			if(!state.info) return null;
			else return state.info.geolocation;
		}
	},
	actions: {
		async createAddress({ dispatch, rootGetters }, address) {
			if(rootGetters['auth/hasUser']) await LocationRepository.create(address).then((response) => { address.id = response.data.id; });
			dispatch('setAddress', address);
		},
		setAddress({ dispatch, commit }, address) {
			commit('setAddress', address);
			dispatch('cart/setType', null, { root: true });
			dispatch('stores/empty', null, { root: true });
		}
	},
	mutations: {
		setAddress(state, address) {
			state.info = address;
		}
	}
}
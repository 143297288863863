<template>
	<div class="list-group-item px-0">
		<div class="d-flex">
			<div class="pe-2 h5">{{ item.quantity }}x</div>
			<div class="flex-fill">
				<div class="d-flex h5">
					<div class="flex-fill">{{ item.name }}</div>
					<div class="flex-fill text-nowrap text-end ps-2">{{ vueNumberFormat(total(), $personalizer.currency.value) }}</div>
				</div>
				<div class="d-flex">
					<div class="flex-fill">
						<p class="text-muted">{{ additionalsAsString() }}</p>
						<p class="text-warning">{{ item.comments }}</p>
					</div>
					<div class="flex-fill text-nowrap text-end" v-if="item.discount > 0">
						<small class="text-danger">{{ vueNumberFormat(discountPercentage(), { prefix: '', suffix: '% OFF' }) }}</small>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import _ from 'lodash';

	export default {
		props: {
			item: Object,
			index: Number
		},
		setup(props) {
			const additionalsAsString = () => {
				var all = [];
				_.forEach(props.item.additionals, (additional) => { all.push(`${additional.name} x ${additional.quantity.toString()}`); });
				return all.join(', ');
			}

			const subtotal = () => {
				var subtotal = props.item.price;
				_.forEach(props.item.additionals, (additional) => { subtotal+= additional.price * additional.quantity; });
				return subtotal * props.item.quantity;
			}

			const total = () => {
				return subtotal() - props.item.discount;
			}

			const discountPercentage = () => {
				return ('discount' in props.item) ? Math.round((props.item.discount * 100 / subtotal()) * 100) / 100 : 0;
			}

			return { additionalsAsString, total, discountPercentage };
		}
	}
</script>

<style scoped="">
</style>
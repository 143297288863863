<template>
	<div class="flex-fill container d-flex align-items-center justify-content-center" v-if="requestUser">
		<div class="permission-card d-flex flex-column place-content-center rounded-4 gradient-bottom-right start-pink-500 end-purple-500 p-7 text-white">
			<h3 class="text-white fw-bolder ls-tight mb-2">Debes estar logueado</h3>
			<p class="lead fw-semibold mb-4">Para poder entablar una conversación con nuestra inteligencia artificial, y así obtener mejores resultados.</p>
			<div class="d-flex justify-content-between">
				<a href="javascript:void(0);" class="lead fw-bold text-white text-opacity-90 text-opacity-100" @click="redirectBack">Cancelar</a>
				<router-link :to="{name: 'identify.index'}" class="lead fw-bold text-white text-opacity-90 text-opacity-100"><i class="bi bi-box-arrow-in-right me-2"></i>Identificate</router-link>
			</div>
		</div>
	</div>
	<div class="flex-fill container d-flex align-items-center justify-content-center" v-else-if="requestLocation">
		<div class="permission-card d-flex flex-column place-content-center rounded-4 gradient-bottom-right start-indigo-500 end-cyan-500 p-7 text-white">
			<h3 class="text-white fw-bolder ls-tight mb-2">Debes ingresar tu ubicación</h3>
			<p class="lead fw-semibold mb-4">Para obtener el listado de tiendas cercanas, y así obtener mejores resultados.</p>
			<div class="d-flex justify-content-between">
				<a href="javascript:void(0);" class="lead fw-bold text-white text-opacity-90 text-opacity-100" @click="redirectBack">Cancelar</a>
				<router-link :to="{name: 'location.create'}" class="lead fw-bold text-white text-opacity-90 text-opacity-100"><i class="bi bi-geo-alt me-2"></i>Ingresar dirección</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		emits: ['redirectBack'],
		props: {
			requestUser: Boolean,
			requestLocation: Boolean
		},
		setup(props, { emit }) {
			const redirectBack = () => emit('redirectBack');

			return { redirectBack };
		}
	}
</script>

<style scoped>
	.permission-card {
		max-width: 500px;
	}
</style>
<template>
	<div class="row" v-if="!obtained">
		<common-message-loading class="col-12" text="Cargando tiendas" />
	</div>
	<div class="row mb-8" v-if="obtained && items.length >= 5">
		<div class="col-12">
			<stores-marketing-items-index :items="items" />
		</div>
	</div>
	<div class="row gy-6" v-if="obtained && list.length > 0">
		<div class="col-12 text-center text-md-start">
			<h3 class="ls-tight lh-xs fw-bolder">Comercios para vos</h3>
			<p class="lh-sm">Disponibles para tu ubicación</p>
		</div>
		<div class="col-12">
			<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-5">
				<div class="col" v-for="store in list" v-bind:key="store.id">
					<store-card-main :store="store" />
				</div>
			</div>
		</div>
	</div>
	<common-message-empty v-if="obtained && list.length == 0">
		<div class="px-5">
			<div class="lh-xs ls-tight display-5 text-dark">Ups!</div>
			<div class="h3 mt-3">No encontramos tiendas <span class="d-inline-block">cercanas a tu ubicación:</span><br><u>{{ complete }}</u></div>
			<div class="mt-8">
				<router-link :to="{name: 'location.index'}" class="btn btn-dark" v-if="hasUser">Mis direcciones</router-link>
				<router-link :to="{name: 'location.create'}" class="btn btn-dark" v-else>Cambiar ubicación</router-link>
			</div>
		</div>
	</common-message-empty>
</template>

<script>
	import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
	import store from '@/store';
	import StoresRepository from '@/repositories/StoresRepository';
	import composableForm from '@/composables/form';
	import moment from 'moment';
	import _ from 'lodash';

	export default {
		setup() {
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const address = computed(() => store.getters['location/address']);
			const complete = computed(() => store.getters['location/complete']);
			const obtained = computed(() => store.getters['stores/obtained']);
			const stores = computed(() => store.getters['stores/list']);
			const expires_in = computed(() => store.getters['stores/expires_in']);
			const { catchError } = composableForm();
			const timeout = ref(null);

			const list = computed(() => {
				if(stores.value.length > 0) return _.orderBy(stores.value, ['open', 'random'], ['desc', 'asc']);
				else return [];
			});

			const items = computed(() => {
				var list = [];

				_.forEach(_.filter(stores.value, { open: true }), (store) => {
					var items_attractives = _.map(store?.items_attractives, function(item) { return _.extend({}, item, { store_id: store.id }); });
					if(items_attractives.length > 0) list = _.union(list, items_attractives);
				});

				return _.orderBy(list, ['attractive'], ['desc']);
			});

			const getStores = async () => {
				if(obtained.value) {
					if(stores.value.length > 0) {
						await StoresRepository.getStatus(_.map(stores.value, 'id'))
							.then((response) => {
								store.dispatch('stores/setStatus', response.data);
							}).catch(catchError);
					}
				}else{
					await StoresRepository.getList({ type: 'by_coordinates', geolocation: address.value.geolocation })
						.then((response) => {
							store.dispatch('stores/setStores', response.data);
						}).catch(catchError);
				}
			}

			const checkStores = async () => {
				if(!obtained.value || moment().diff(expires_in.value, 'seconds') >= 0) await getStores();
				timeout.value = setTimeout(checkStores, (expires_in.value) ? Math.abs(moment().diff(expires_in.value, 'milliseconds')) : 20000);
			}

			onMounted(checkStores);

			onBeforeUnmount(() => {
				if(timeout.value) clearTimeout(timeout.value);
			});

			return { hasUser, complete, obtained, list, items };
		}
	}
</script>
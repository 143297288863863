<template>
	<button class="btn btn-success d-flex align-items-center rounded-5 w-100" :disabled="submitting" @click="login">
		<span class="icon icon-sm pe-2">
			<i class="bi bi-whatsapp"></i>
		</span>
		<div class="flex-fill ms-n5">Continuar con Celular</div>
	</button>
</template>

<script>
	import router from '@/router';

	export default {
		props: {
			submitting: Boolean
		},
		setup() {
			const login = () => {
				router.push({ name: 'identify.phone' });
			}

			return { login };
		}
	}
</script>
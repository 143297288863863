<template>
	<layouts-form-input-general :inputId="inputId" :title="title" :subtitle="subtitle" :validation="validation">
		<div class="input-group" :class="{'is-invalid': validation?.$error}">
			<div class="form-floating">
				<input type="tel"
						:id="inputId"
						:value="modelValue"
						@input="emitInput"
						class="form-control"
						:class="{'is-invalid': validation?.$error}"
						:placeholder="placeholder"
						v-focus="focus">
				<label for="phone">{{ title }}</label>
			</div>
			<span class="input-group-text bg-white w-rem-14 justify-content-center" v-if="country"><country-flag :country="country" size="small" /></span>
		</div>
	</layouts-form-input-general>
</template>

<script>
	import { ref } from 'vue';
	import { parsePhoneNumberWithError } from 'libphonenumber-js';
	import CountryFlag from 'vue-country-flag-next';

	export default {
		components: { CountryFlag },
		props: {
			modelValue: String,
			title: String,
			subtitle: String,
			placeholder: String,
			validation: Object,
			focus: {
				type: Boolean,
				default: false
			}
		},
		setup(props, { emit }) {
			const inputId = `input-${Math.random().toString(36).substr(2, 9)}`;
			const inputValue = ref(props.modelValue);
			const country = ref(null);

			const emitInput = (event) => {
				inputValue.value = event.target.value;
				emit('update:modelValue', inputValue.value);
				parsePhone();
			};

			const parsePhone = () => {
				country.value = null;

				try {
					const parsed = parsePhoneNumberWithError(inputValue.value);
					country.value = parsed.country;
				}catch(error) {
					console.error(error);
				}
			}

			return { inputId, inputValue, emitInput, country };
		}
	}
</script>
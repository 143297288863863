<template>
	<div class="hstack gap-3 align-items-center position-relative">
		<a href="javascript:void(0);" class="btn btn-link p-0 text-reset text-md stretched-link" @click="doRedirect"><i class="bi bi-chevron-left"></i></a>
		<div class="vr opacity-20 my-1"></div>
		<div class="h5 ls-tight">
			Volver
			<span v-show="!!description">{{ description }}</span>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import router from '@/router';
	import composableRedirect from '@/composables/redirect';

	export default {
		props: {
			redirect: Object
		},
		setup(props) {
			const { redirectBackInfo, redirectBack } = composableRedirect();

			const description = computed(() => {
				if(props.redirect) return props.redirect.description;
				else return redirectBackInfo.value.description;
			});

			const doRedirect = () => {
				if(props.redirect) router.push({ path: props.redirect.path });
				else redirectBack();
			}

			return { description, doRedirect };
		}
	}
</script>
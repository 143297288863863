<template>
	<div class="mt-5 border border-2 border-dark rounded-3">
		<div class="p-5 border-top border-4 rounded-3 border-primary">
			<div class="row justify-content-center">
				<div class="col-12 d-md-flex gap-20 align-items-center">
					<div>
						<h4 class="lh-xs fw-bolder"><i class="bi bi-stars pe-1"></i>Hablá con el asistente virtual</h4>
						<p class="lh-xs mt-2 mt-md-1">Por consultas sobre productos o para obtener sugerencias, podés hablar con nuestra <b class="d-inline-block text-primary">inteligencia artificial</b>.</p>
					</div>
					<div class="flex-fill mt-4 mt-md-0">
						<router-link :to="{name: 'assistant'}" class="btn btn-sm btn-primary border border-2 border-dark w-100">Ingresar al asistente</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<template>
	<button class="btn btn-google d-flex align-items-center rounded-5 w-100" :disabled="submitting" @click="login">
		<span class="icon icon-sm pe-2">
			<i class="bi bi-google"></i>
		</span>
		<div class="flex-fill ms-n5">Continuar con Google</div>
	</button>

	<user-identify-modal-google />
</template>

<script>
	import { onMounted } from 'vue';
	import store from '@/store';
	import { Modal } from 'bootstrap';
	import { googleOneTap, googleTokenLogin } from 'vue3-google-login';
	import composableForm from '@/composables/form';

	export default {
		emits: ['setUser'],
		props: {
			submitting: Boolean,
			description: String
		},
		setup(props, { emit }) {
			const { catchError } = composableForm();

			const login = () => {
				var userAgent = navigator.userAgent;
				if(!userAgent.match(/instagram/i)) {
					googleTokenLogin().then((response) => {
						store.dispatch('auth/socialClient', { provider: 'google', data: response }).then(() => emit('setUser')).catch(catchError);
					}).catch(catchError);
				}else{
					var modalGoogle = new Modal(document.getElementById('user-identify-modal-google'));
					modalGoogle.show();
				}
			}

			const oneTap = (response) => {
				store.dispatch('auth/socialClient', { provider: 'google', data: response }).then(() => emit('setUser')).catch(catchError);
			}

			onMounted(() => {
				googleOneTap().then(oneTap);
			});

			return { login };
		}
	}
</script>
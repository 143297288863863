import moment from 'moment';
import _ from 'lodash';

export default {
	namespaced: true,
	state: {
		obtained: false,
		list: [],
		expires_in: null
	},
	getters: {
		obtained(state) {
			return state.obtained;
		},
		list(state) {
			return state.list;
		},
		expires_in(state) {
			return state.expires_in;
		}
	},
	actions: {
		empty({ commit }) {
			commit('setObtained', false);
			commit('setStores', []);
			commit('setExpires', null);
		},
		setObtained({ commit }, obtained) {
			commit('setObtained', obtained);
		},
		setStores({ state, commit, dispatch }, list) {
			//Establece un número aleatorio con el fin de que el orden en el listado no sea siempre el mismo
			if(!state.obtained) _.map(list, function(store) { store.random = Math.random(); return store; });

			commit('setStores', list);
			dispatch('setObtained', true);
			dispatch('setExpires');
		},
		setStatus({ state, dispatch }, data) {
			var list = _.clone(state.list);
			_.map(list, function(store) {
				var store_status = _.find(data, (row) => { return row.id == store.id });

				if(store_status) {
					store.open = store_status.open;
					store.delay_show = store_status.delay_show;
				}

				return store;
			});

			dispatch('setStores', list);
		},
		setExpires({ commit }) {
			commit('setExpires',  moment().add(10, 'minutes').format());
		}
	},
	mutations: {
		setObtained(state, obtained) {
			state.obtained = obtained;
		},
		setStores(state, stores) {
			state.list = stores;
		},
		setExpires(state, expires_in) {
			state.expires_in = expires_in;
		}
	}
}
export default {
	namespaced: true,
	state: {
		landing: null
	},
	getters: {
		landing(state) {
			return state.landing;
		}
	},
	actions: {
		setLanding({ state, commit }, landing) {
			if(state.landing == null) commit('setLanding', landing);
		}
	},
	mutations: {
		setLanding(state, landing) {
			state.landing = landing;
		}
	}
}
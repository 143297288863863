export default {
	namespaced: true,
	state: {
		info: null
	},
	getters: {
		isDefined(state) {
			return !!state.info;
		},
		info(state) {
			return state.info;
		},
		type(state) {
			if(!state.info) return null;
			else return state.info.type;
		},
		stores(state) {
			if(!state.info) return [];
			else return state.info.stores;
		},
		description(state) {
			if(!state.info) return null;
			else return state.info.description;
		},
		group(state) {
			if(!state.info) return null;
			else return state.info.group;
		}
	},
	actions: {
		setCode({ dispatch, commit }, code) {
			commit('setCode', code);
			dispatch('cart/empty', null, { root: true });
		}
	},
	mutations: {
		setCode(state, code) {
			state.info = code;
		}
	}
}
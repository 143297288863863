import TokensRepository from '@/repositories/TokensRepository';
import UsersRepository from '@/repositories/UsersRepository';
import MeRepository from '@/repositories/MeRepository';

const AuthModule = {
	namespaced: true,
	state: () => ({
		status: 'guest',
		user: null,
		token: null
	}),
	getters: {
		token(state) {
			return state.token;
		},
		hasUser(state) {
			return (state.user != null);
		},
		user(state) {
			return state.user;
		},
		userHasPhone(state) {
			if (state.user == null) return false;
			else return state.user.phone != null;
		},
		userHasVerifiedPhone(state) {
			if (state.user == null) return false;
			else return !!state.user.phone_verified;
		},
		userGetPicture(state) {
			if (state.user == null) return '';
			else return state.user.avatar;
		},
		userGetFullname(state) {
			if (state.user == null) return '';
			else return state.user.fullname;
		},
		userGetEmail(state) {
			if (state.user == null) return '';
			else return state.user.email;
		},
		userGetPhone(state) {
			if (state.user == null) return '';
			else return state.user.phone;
		},
		orderRequestFilters(state, getters, rootState, rootGetters) {
			if(!getters['hasUser']) return { name: 'identify.index' };

			var userVerification = rootGetters['store/userVerification'];
			if(userVerification.length > 0 && userVerification.indexOf('phone_create') !== -1 && !getters['userHasPhone']) return { name: 'user.phone.update' };
			if(userVerification.length > 0 && userVerification.indexOf('phone_verification') !== -1 && !getters['userHasVerifiedPhone']) return { name: 'user.phone.verification' };

			return null;
		}
	},
	actions: {
		status({ commit }, status) {
			commit('setStatus', status);
		},
		signin({ commit }, data) {
			return UsersRepository.signin(data).then(response => {
				commit('setToken', response.data);
			});
		},
		login({ commit }, data) {
			return TokensRepository.create(data).then(response => {
				commit('setToken', response.data);
			});
		},
		socialClient({ commit }, data) {
			return TokensRepository.socialClient(data.provider, data.data).then(response => {
				commit('setToken', response.data);
			});
		},
		socialServer({ commit }, data) {
			return TokensRepository.socialServer(data.provider, data.data).then(response => {
				commit('setToken', response.data);
			});
		},
		otp({ commit }, data) {
			return TokensRepository.otpExchange(data).then(response => {
				commit('setToken', response.data);
			});
		},
		me({ commit, dispatch }) {
			return MeRepository.me().then(response => {
				commit('setUser', response.data);
				commit('setStatus', 'authenticated');
			}).catch(() => dispatch('logout'));
		},
		logout({ commit }) {
			commit('logout');
		},
		// Cuando el user actualiza sus datos ejecutamos esto ...
		setUser({ commit }, data) {
			commit('setUser', data);
		},
		phoneVerified({ commit }) {
			commit('phoneVerified', true);
		},
		updatePhone({ commit }, value) {
			commit('setPhone', value);
			commit('phoneVerified', false);
		}
	},
	mutations: {
		setUser(state, aUserProfile) {
			state.user = aUserProfile;
		},
		setToken(state, aToken) {
			state.token = aToken;
		},
		loadToken(state, aToken) {
			state.token = aToken;
		},
		loadUser(state, anUser) {
			state.user = anUser;
		},
		setStatus(state, status) {
			state.status = status;
		},
		logout(state) {
			state.status = 'guest';
			state.token = null;
			state.user = null;
		},
		phoneVerified(state, verified) {
			state.user.phone_verified = verified;
		},
		setPhone(state, value) {
			state.user.phone = value;
		}
	}
};

export default AuthModule;

<template>
	<div class="modal" id="modal-redirect" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-md">
			<div class="modal-content shadow-3">
				<div class="modal-body">
					<store-card-small :store="store" v-if="store" />
				</div>
				<div class="modal-footer justify-content-between">
					<button type="button" class="btn btn-sm btn-danger w-100 position-relative" data-bs-dismiss="modal">
						<div class="button-progress" style="width: 0%;"></div>
						<div class="button-text">Cancelar redireccionamiento</div>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			store: Object
		},
		setup() {
		}
	}
</script>

<style scoped>
	.btn-danger {
		background-color: #ff648b;
		border-color: #ff648b;
	}

	.button-progress {
		position: absolute;
		height: 100%;
		width: 0%;
		top: 0;
		left: 0;
		border-radius: 0.375rem;
		background: rgba(255, 0, 0, 1);
		transition: width 5s;
	}

	.button-text {
		position: relative;
	}
</style>
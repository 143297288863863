<template>
	<component v-for="message in messages" v-bind:key="message.id" :type="type" :store="store" :message="message" @selectStore="selectStore" @selectItem="selectItem" :is="`assistant-message-${message.from}`" />
</template>

<script>
	export default {
		emits: ['selectStore', 'selectItem'],
		props: {
			type: String,
			store: Object,
			messages: Array
		},
		setup(props, { emit }) {
			const selectStore = (data) => emit('selectStore', data);
			const selectItem = (data) => emit('selectItem', data);

			return { selectStore, selectItem };
		}
	}
</script>
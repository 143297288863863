<template>
	<div id="menu-promocode" class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header container container-offcanvas">
			<h5 class="offcanvas-title" id="offcanvasBottomLabel">Usar código promocional</h5>
			<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body container container-offcanvas pt-0">
			<form @submit.prevent="submit">
				<div class="mb-7">
					<input type="text" id="code" class="form-control font-code" :class="{'is-invalid': validation.code.$error}" v-model="code" placeholder="Ingrese aquí el código" maxlength="20">
					<span class="mt-1 invalid-feedback" v-for="error of validation.code.$errors" :key="error.$uid">{{ error.$message }}</span>
				</div>
				<div class="d-grid gap-2 d-md-flex justify-content-md-end">
					<button type="button" class="btn btn-sm btn-neutral me-md-2" data-bs-dismiss="offcanvas">Cancelar</button>
					<button type="submit" class="btn btn-sm btn-dark" :disabled="submitting" @click="submit">Aplicar código</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import { ref, reactive, watch, toRefs } from 'vue';
	import store from '@/store';
	import useVuelidate from '@vuelidate/core';
	import { required, minLength, maxLength } from '@/helpers/i18n/validators';
	import StoreRepository from '@/repositories/StoreRepository';
	import composableForm from '@/composables/form';

	export default {
		emits: ['setPromocode'],
		setup(props, { emit }) {
			const { catchError } = composableForm();

			const form = reactive({
				code: null
			});

			watch(() => form.code, (newValue) => form.code = newValue.replace(/\s/, '').toUpperCase());

			const validation = useVuelidate({
				code: {
					required,
					minLength: minLength(3),
					maxLength: maxLength(20)
				}
			}, form);

			const submitting = ref(false);
			const submit = async () => {
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				await StoreRepository.getPromocode(store.getters['cart/slug'], { code: form.code })
					.then((response) => emit('setPromocode', response.data))
					.catch((e) => catchError(e, true));
				submitting.value = false;
			}

			return { ...toRefs(form), validation, submitting, submit };
		}
	}
</script>
export default {
	namespaced: true,
	state: {
		back: null,
		next: null
	},
	getters: {
		back(state) {
			if(!state.back) return { description: 'A Wantti', path: '/' };
			else return state.back;
		},
		next(state) {
			return state.next;
		}
	},
	actions: {
		setBack({ commit }, data) {
			commit('setBack', data);
		},
		setNext({ commit }, data) {
			commit('setNext', data);
		}
	},
	mutations: {
		setBack(state, data) {
			state.back = data;
		},
		setNext(state, data) {
			state.next = data;
		}
	}
}
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import _ from 'lodash';

export default function() {
	const toast = useToast();
	const submitting = ref(false);
	const validationExternal = ref([]);

	const catchError = (e, asAlert = false) => {
		if(typeof e.response == 'undefined') {
			toast.error('Ocurrió un error con la petición.');
		}else switch(e.response.status) {
			case 401:
				toast.error('El usuario no se encuentra logueado.');
				break;
			case 404:
				toast.error('No se encontró la información que buscaba.');
				break;
			case 429:
				toast.error('Demasiados intentos, aguarde un momento y vuelva a intentar.');
				break;
			default:
				if(e.response && e.response.data) displayServerErrors(e.response.data, asAlert);
				else toast.error('No se pudo procesar su solicitud, por favor intente en unos momentos.');
				break;
		}

		submitting.value = false;
	}

	const displayServerErrors = (errors, asAlert = false) => {
		var messages = (errors.message) ? [errors.message] : ((errors.errors) ? errors.errors : _.map(errors, 'message'));

		if(asAlert) _.forEach(messages, function(message) { toast.error(message); });
		else validationExternal.value = messages;
	}

	return { submitting, validationExternal, catchError, displayServerErrors };
}

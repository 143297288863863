<template>
	<div class="modal fade" id="modal-image" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-md">
			<div class="modal-content shadow-3">
				<div class="modal-body p-0">
					<button type="button" class="btn btn-sm btn-neutral position-absolute m-3 btn-image-show" data-bs-dismiss="modal" aria-label="Close"><i class="bi bi-arrows-angle-contract"></i></button>
					<img :src="image" class="img-fluid" alt="image">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			image: String
		}
	}
</script>

<style scoped>
	.btn-image-show {
		padding: .25rem .5rem !important;
	}

	.btn-image-show i {
		font-size: 12px;
	}
</style>
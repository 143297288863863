/* eslint-disable no-console */
import { register } from 'register-service-worker';
import { useToast } from 'vue-toastification';

if('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
	const toast = useToast();

	register(`/app-service-worker.js`, {
		ready() {
			console.log(
				'App is being served from cache by a service worker.\n' +
				'For more details, visit https://goo.gl/AFskqB'
			);
		},
		registered() {
			console.log('Service worker has been registered.');
		},
		cached() {
			console.log('Content has been cached for offline use.');
		},
		updatefound() {
			console.log('New content is downloading.');
		},
		updated(registration) {
			toast.warning('La aplicación fue actualizada, recargando...', {
				timeout: 2000,
				onClose: () => {
					registration.waiting.postMessage({ action: 'skipWaiting' });
				}
			});
		},
		offline() {
			console.log('No internet connection found. App is running in offline mode.');
		},
		error(error) {
			console.error('Error during service worker registration:', error);
		}
	});

	var refreshing = false;
	navigator.serviceWorker.addEventListener('controllerchange', function() {
		if(refreshing) return;
		window.location.reload();
		refreshing = true;
	});
}
<template>
	<div class="h-100">
		<common-menu-main :store="current_store" :styles="microsite?.styles" />
		<div class="d-flex flex-column min-h-full" :class="{'has-cover': (current_store.cover != null)}">
			<!-- Cover -->
			<div class="container bg-cover store-cover" :style="{'background-image': `url(${current_store.cover})`}" v-if="coverReady"></div>
			<Skeletor height="100%" class="container w-100 store-cover" v-if="coverExist && !coverReady" />
			<!-- Info -->
			<div id="store-container" class="container">
				<div class="row g-0" :class="{'align-items-end': !!coverExist, 'align-items-center mt-5': !coverExist}">
					<div class="col-auto" v-if="logoExist">
						<!-- Avatar -->
						<div class="avatar avatar-cover border border-body border-4 bg-white ms-n1" :class="{'mt-n8': !!coverExist}">
							<img :src="current_store.logo_url" alt="logo" @load="logoReady = true" v-show="logoReady">
							<Skeletor width="100%" height="100%" class="rounded" v-show="!logoReady" />
						</div>
					</div>
					<div class="col position-relative me-0 me-md-5" :class="{'pt-3 pb-2': coverExist, 'ps-3': logoExist}">
						<!-- Title -->
						<a href="javascript:void(0);" class="h3 lh-1 text-dark stretched-link" @click="showInfo" v-show="isDefined">{{ current_store.name }}</a>
						<Skeletor class="h3 mt-2" style="max-width: 90%;" v-show="!isDefined" />
						<div class="text-xs text-uppercase text-muted lh-sm fw-semibold" v-if="current_store.description">{{ current_store.description }}</div>
						<div class="text-xs text-uppercase text-muted lh-sm fw-semibold" v-else>{{ address }}</div>
						<Skeletor class="text-xs text-uppercase text-muted fw-semibold mt-1" style="max-width: 90%;" v-show="!isDefined" />
					</div>
					<div class="col-12 col-md-auto mt-4 mt-md-0 hstack gap-2" v-show="isDefined && displayInfo">
						<!-- Button -->
						<router-link :to="{name: 'store.assistant'}" class="btn btn-sm btn-neutral px-3" v-if="current_store.type == 'store' && current_store.marketplace"><i class="bi bi-stars pe-2"></i>Asistente</router-link>
						<button type="button" class="btn btn-sm btn-neutral px-3" @click="showStatus" v-if="current_store.type == 'store' && current_store.open"><i class="bi bi-clock-history pe-2"></i>{{ delay_show }}</button>
						<button type="button" class="btn btn-sm btn-neutral px-3" @click="showStatus" v-if="current_store.type == 'store' && !current_store.open"><i class="bi bi-lock pe-2"></i>Cerrado</button>
						<button type="button" class="btn btn-sm btn-neutral" @click="showStatus" v-if="current_store.type == 'market'">Horarios</button>
						<button type="button" class="btn btn-sm btn-neutral btn-square rounded-circle" @click="showInfo" v-if="isDefined"><i class="bi bi-info-lg text-lg text-muted"></i></button>
						<router-link :to="{name: 'store.links'}" class="btn btn-sm btn-neutral btn-square rounded-circle" v-if="isDefined && current_store.links.length > 0"><i class="bi bi-link-45deg text-lg text-muted"></i></router-link>
					</div>
				</div>
			</div>
			<div class="container pt-2">
				<slot />
			</div>
			<common-footer-microsite v-if="microsite?.id" />
		</div>
		<cart-button />
	</div>
</template>

<script>
	import { ref, computed, onMounted, watch } from 'vue';
	import composableStore from '@/composables/store';

	export default {
		props: {
			microsite: Object
		},
		setup() {
			const { isDefined, current_store, displayInfo, showInfo, showStatus } = composableStore();
			const coverExist = ref(true);
			const coverReady = ref(false);
			const logoExist = ref(true);
			const logoReady = ref(false);

			const address = computed(() => {
				var data = null;

				switch(current_store.value.type) {
					case 'store':
					case 'market':
						data = current_store.value.address.small;
						break;
					case 'headquarters':
						data = 'Casa matriz';
						break;
				}

				return data;
			});

			const delay_show = computed(() => {
				return `${(current_store.value.delay_show == 0) ? 'Sin demora' : `Demora: ${current_store.value.delay_show}´`}`;
			});

			const imagesLoad = () => {
				coverExist.value = true;
				coverReady.value = false;
				logoExist.value = true;
				logoReady.value = false;

				if(isDefined.value) {
					coverExist.value = !!current_store.value.cover;
					logoExist.value = !!current_store.value.logo_url;

					if(current_store.value.cover != null) {
						var loadImage = new Image();
						loadImage.src = current_store.value.cover;
						loadImage.onload = function() { coverReady.value = true; };
					}
				}
			}

			watch(isDefined, imagesLoad);
			onMounted(imagesLoad);

			return { isDefined, current_store, coverExist, coverReady, logoExist, logoReady, address, delay_show, displayInfo, showInfo, showStatus };
		}
	}
</script>

<style scoped>
	.avatar-cover {
		width: 6.25rem;
		min-width: 6.25rem;
		height: 6.25rem;
		min-height: 6.25rem;
	}

	.store-cover {
		aspect-ratio: auto 1031 / 580;
		max-height: 300px;
		background-position: center center;
	}

	.stretched-link {
		line-height: 1rem;
	}

	header {
		background-color: var(--bs-body-bg);
	}
</style>
import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import composableMicrosite from '@/composables/microsite';

//Styles
import '@/assets/css/customization.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@/assets/css/vue-stories.css';

//Document height
const documentHeight = () => document.documentElement.style.setProperty('--doc-height', `${window.innerHeight}px`);
window.addEventListener('resize', documentHeight);
documentHeight();

const app = createApp({
	extends: App,
	methods: {
		checkUser() {
			if(store.getters['auth/hasUser']) store.dispatch('auth/me');
		}
	},
	beforeMount() {
		const { isMicrosite, isDefined, init } = composableMicrosite();
		if(isMicrosite.value && !isDefined.value) init();

		this.checkUser();
		setInterval(this.checkUser, (5 * 60000));
	}
});

//Vue Store
import store from '@/store';
app.use(store);

//Vue Router
import router from '@/router';
app.use(router);

//Register all "Single File Components"
const files = require.context('./components', true, /\.vue$/i);
files.keys().map(key => {
	var routeArray = key.split('/');
	routeArray.shift();
	routeArray[routeArray.length - 1] = routeArray[routeArray.length - 1].split('.')[0].toLowerCase();
	app.component(routeArray.join('-'), files(key).default);
});

//Vue Google Login
import vue3GoogleLogin from 'vue3-google-login';
app.use(vue3GoogleLogin, { clientId: process.env.VUE_APP_GOOGLE_LOGIN_CLIENT_ID });

//Vue Google Analytics
import VueGtag from 'vue-gtag-next';
app.use(VueGtag, { property: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }});

//Vue Google Maps
import VueGoogleMaps from '@fawmi/vue-google-maps';
app.use(VueGoogleMaps, { load: { key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY, libraries: 'places' } });

//Vue Skeletor
import { Skeletor } from 'vue-skeletor';
import '@/assets/css/vue-skeletor.css';
app.component(Skeletor.name, Skeletor);

//Vue Number Format
import VueNumberFormat from 'vue-number-format';
app.use(VueNumberFormat, { prefix: '$ ', thounsand: '.', decimal: ',', precision: 2, acceptNegative: true });

//Vue ScrollTo
import VueScrollTo from 'vue-scrollto';
app.use(VueScrollTo);

//Vue Toast
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
app.use(Toast, { position: 'top-center', transition: 'Vue-Toastification__fade', timeout: 3000, closeOnClick: true, pauseOnFocusLoss: true, pauseOnHover: true, draggable: true, showCloseButtonOnHover: false, closeButton: false, icon: false });

//Moment Js
import moment from 'moment';
import 'moment/locale/es';
app.use(moment);

//Maska
import Maska from 'maska';
app.use(Maska);

//Vue Cookie Next
import { VueCookieNext } from 'vue-cookie-next';
app.use(VueCookieNext);

//Vue3 Touch Events
import Vue3TouchEvents from 'vue3-touch-events';
app.use(Vue3TouchEvents, { touchHoldTolerance: 400 });

//Personalizer
import helperPersonalizer from '@/helpers/Personalizer';
app.use(helperPersonalizer);

//Vue Focus directive
app.directive('focus', { mounted(el, active) { if(active.value == undefined || active.value == true) el.focus(); } });

//App Mount
app.mount('body');
import { computed } from 'vue';
import moment from 'moment';
import _ from 'lodash';

export default function(order) {
	const STATUSES = {
		'PAYING': {
			title: 'Pagando',
			class: 'bg-warning',
			refresh: 30,
			vibrate: false,
			message: false,
			progress: {
				percentage: 20,
				class: 'bg-warning'
			}
		},
		'PENDING': {
			title: 'Pendiente',
			class: 'bg-warning',
			refresh: 30,
			vibrate: false,
			message: false,
			progress: {
				percentage: 20,
				class: 'bg-warning'
			}
		},
		'ACCEPTED': {
			title: 'Aceptado',
			class: ' bg-success',
			refresh: 30,
			vibrate: [500, 250, 500, 250],
			message: '¡Tu pedido fue aceptado!',
			progress: {
				percentage: 50,
				class: 'bg-success'
			}
		},
		'DELIVER': {
			title: 'Por entregar',
			class: ' bg-info',
			refresh: 60,
			vibrate: [500, 250, 500, 250, 500, 250],
			message: '¡Tu pedido está listo para ser entregado!',
			progress: {
				percentage: 80,
				class: 'bg-info'
			}
		},
		'COMPLETE': {
			title: 'Entregado',
			class: 'bg-dark',
			refresh: false,
			vibrate: [500, 250, 500, 250, 500, 250],
			message: '¡Tu pedido fue entregado!',
			progress: {
				percentage: 100,
				class: 'bg-dark'
			}
		},
		'REJECTED': {
			title: 'Rechazado',
			class: 'bg-danger',
			refresh: false,
			vibrate: [500, 250, 500, 250, 500, 250],
			message: 'Lo sentimos, tu pedido fue rechazado',
			progress: {
				percentage: 100,
				class: 'bg-danger'
			}
		},
	}

	const PAYMENT_METHODS = {
		'agree': 'A acordar',
		'cash': 'Efectivo',
		'card': 'Tarjeta',
		'card-credit': 'Tarjeta de crédito',
		'card-debit': 'Tarjeta de débito',
		'mercadopago-online': 'Tarjeta',
		'mercadopago-qr': 'MercadoPago QR',
		'cuenta-dni': 'Cuenta DNI',
		'bank-transfer': 'Transferencia bancaria',
		'bancard': 'Tarjeta',
		'pos-mobile': 'POS Móvil'
	}

	const getStatusTitle = computed(() => {
		return (_.has(STATUSES, order.value.status))
			? STATUSES[order.value.status].title
			: '-';
	});

	const getStatusClass = computed(() => {
		return (_.has(STATUSES, order.value.status))
			? STATUSES[order.value.status].class
			: '';
	});

	const getCreatedAtFormatted = computed(() => moment(order.value.created_at).format('D [de] MMMM [de] YYYY'));

	const getDeliveredDateFormatted = computed(() => {
		var description = null;

		if(!order.value.ready_by) description = null;
		else if(order.value.type == 'DELIVERY') description = moment(order.value.ready_by).add(Number(process.env.VUE_APP_DELIVERY_FROM), 'minutes').format('D [de] MMMM [de] YYYY');
		else description = moment(order.value.ready_by).format('D [de] MMMM [de] YYYY');

		return description;
	});

	const getDeliveredTimeFormatted = computed(() => {
		var description = null,
			ready_by = null,
			ready_from = null,
			ready_to = null;

		if(!order.value.ready_by) {
			description = `A confirmar`;
		}else if(order.value.type == 'DELIVERY') {
			ready_from = moment(order.value.ready_by).add(Number(process.env.VUE_APP_DELIVERY_FROM), 'minutes').format('HH:mm');
			ready_to = moment(order.value.ready_by).add(Number(process.env.VUE_APP_DELIVERY_TO), 'minutes').format('HH:mm');
			description = `Entre las ${ready_from} hs y ${ready_to} hs`;
		}else{
			ready_by = moment(order.value.ready_by).format('HH:mm');
			description = `A partir de las ${ready_by} hs`;
		}

		return description;
	});

	const getType = computed(() => {
		var description = null;

		switch(order.value.type) {
			case 'DELIVERY':
				description = 'Delivery';
				break;
			case 'TAKEAWAY':
				description = 'Take away';
				break;
			case 'LOCAL':
				description = 'En el comercio';
				break;
			default:
				description = 'Sin especificar';
				break;
		}

		return description;
	});

	const getDeliveryTo = computed(() => {
		var description = null;

		switch(order.value.type) {
			case 'DELIVERY':
				description = order.value.address ?? 'Sin descripción';
				break;
			case 'LOCAL':
				description = (order.value.code && order.value.code.description) ? order.value.code.description : null;
				break;
		}

		return description;
	});

	const getPaymentMethod = computed(() => {
		var descriptions = [];
		_.forEach(order.value.payments, function(payment) {
			if(_.has(PAYMENT_METHODS, payment.method)) {
				var description = PAYMENT_METHODS[payment.method];

				switch(payment.status) {
					case 'PENDING': description = `<span class="text-warning">${description} (Pendiente)</span>`; break;
					case 'REJECTED': description = `<span class="text-danger">${description} (Rechazado)</span>`; break;
				}

				descriptions.push(description);
			}
		});

		return (descriptions.length > 0) ? descriptions.join('<br>') : 'Sin pagos';
	});

	const dataRefresh = computed(() => {
		return (_.has(STATUSES, order.value.status))
			? STATUSES[order.value.status].refresh
			: false;
	});

	return { getStatusTitle, getStatusClass, getCreatedAtFormatted, getDeliveredDateFormatted, getDeliveredTimeFormatted, getType, getDeliveryTo, getPaymentMethod, dataRefresh };
}
<template>
	<div class="container">
		<div class="justify-content-center">
			<div class="d-flex flex-column position-relative">
				<slot />
			</div>
		</div>
	</div>
</template>

<style scoped>
	.container {
		max-width: 650px !important;
	}
</style>
<template>
	<button class="btn btn-facebook d-flex align-items-center rounded-5 w-100" :disabled="submitting" @click="login">
		<span class="icon icon-sm pe-2">
			<i class="bi bi-facebook"></i>
		</span>
		<div class="flex-fill ms-n5">Continuar con Facebook</div>
	</button>
</template>

<script>
	export default {
		props: {
			submitting: Boolean
		},
		setup() {
			const login = () => {
				window.location.replace('/auth/facebook');
			}

			return { login };
		}
	}
</script>
<template>
	<div class="bg-white position-relative d-flex p-3 ps-10 rounded shadow mb-3" role="alert">
		<div>
			<span class="d-block fw-semibold text-heading">Ubicación seleccionada</span>
			<b class="me-2" v-if="location.description">{{ location.description }}</b>
			<span class="mb-0">{{ complete }}</span>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';

	export default {
		setup() {
			const location = computed(() => store.getters['location/address']);
			const complete = computed(() => store.getters['location/complete']);

			return { location, complete };
		}
	}
</script>
<template>
	<div id="menu-schedule" class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header container container-offcanvas">
			<h5 class="offcanvas-title" id="offcanvasBottomLabel">Programar pedido</h5>
			<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body container container-offcanvas pt-0">
			<div class="mb-3">
				<label class="form-label" for="formInputExample">Seleccionar día</label>
				<select class="form-select" :class="{'is-invalid': validation.day.$error}" v-model="day">
					<option value="-1" selected>Seleccionar</option>
					<option v-for="day, idx in days" v-bind:key="idx" v-bind:value="idx">{{ day.date_for_humans }}</option>
				</select>
				<span class="mt-1 invalid-feedback" v-for="error of validation.day.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<div class="mb-7">
				<label class="form-label" for="formInputExample">Seleccionar hora</label>
				<select class="form-select" :class="{'is-invalid': validation.hour.$error}" v-model="hour">
					<option value="-1" selected>Seleccionar</option>
					<option v-for="hour, idx in hours" v-bind:key="idx" v-bind:value="idx">{{ humanHourRange(hour) }}</option>
				</select>
				<span class="mt-1 invalid-feedback" v-for="error of validation.hour.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<div class="d-grid gap-2 d-md-flex justify-content-md-end">
				<button type="button" class="btn btn-sm btn-neutral me-md-2" data-bs-dismiss="offcanvas">Cancelar</button>
				<button type="button" class="btn btn-sm btn-dark" :disabled="submitting" @click="submit">Seleccionar</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, reactive, computed, watch, toRefs } from 'vue';
	import store from '@/store';
	import useVuelidate from '@vuelidate/core';
	import { helpers } from '@vuelidate/validators';
	import _ from 'lodash';

	export default {
		emits: ['setSchedule'],
		props: {
			services: Object
		},
		setup(props, { emit }) {
			const form = reactive({
				day: -1,
				hour: -1,
			});

			const type = computed(() => store.getters['cart/type']);
			const days = computed(() => {
				var service = (type.value) ? _.find(props.services, (row) => { return row.type == type.value.option }) : null,
					options = (service) ? _.find(service.options, (row) => { return row.type == 'scheduled' }) : [];

				return (options) ? options.constraints : [];
			});
			const hours = computed(() => {
				if(form.day == -1) return [];
				else return days.value[form.day].hours;
			});

			const hourRange = (range) => {
				return range.from.concat('-').concat(range.to);
			}

			const humanHourRange = (range) => {
				var hour_from = range.from.substring(0, 5);
				var hour_to = range.to.substring(0, 5);
				return 'De '.concat(hour_from).concat(' a ').concat(hour_to).concat(' Hs');
			}

			watch(() => form.day, () => form.hour = -1);

			const validateOption = (value) => {
				return (value != -1);
			}

			const validation = useVuelidate({
				day: {
					validateOption: helpers.withMessage('Debe seleccionar un día', validateOption)
				},
				hour: {
					validateOption: helpers.withMessage('Debe seleccionar una hora', validateOption)
				}
			}, form);

			const submitting = ref(false);
			const submit = async () => {
				if(!await validation.value.$validate()) return;

				submitting.value = true;
				var selected_day = days.value[form.day];
				var selected_hour = selected_day.hours[form.hour];
				emit('setSchedule', {
					option: 'scheduled',
					date: selected_day.date,
					hour: hourRange(selected_hour),
					description: selected_day.date_for_humans.concat(' ').concat(humanHourRange(selected_hour))
				});
				submitting.value = false;
			}

			return { ...toRefs(form), days, hours, humanHourRange, validation, submitting, submit };
		}
	}
</script>
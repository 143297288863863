<template>
	<input type="checkbox" class="form-check-input" v-model="value" :disabled="disabled">
</template>

<script>
	import { ref, watch } from 'vue';

	export default {
		props: {
			initial: {
				type: Number,
				default: 0
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		setup(props, {emit}) {
			const value = ref(props.initial);
			watch(value, (newValue) => emit('updated', (newValue) ? 1 : 0));

			return { value };
		}
	}
</script>

<style scoped>
	input {
		width: 1.5em !important;
		height: 1.5em !important;
		vertical-align: -webkit-baseline-middle !important;
		margin-top: 0 !important;
		margin-bottom: 3px !important;
	}
</style>
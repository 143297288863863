import _ from 'lodash';

export default {
	namespaced: true,
	state: {
		list: []
	},
	getters: {
		list(state) {
			return state.list;
		}
	},
	actions: {
		sync({ commit, state }, aiChat) {
			var list = _.clone(state.list),
				hasAiChat = false;

			_.forEach(list, (o, key) => {
				if(o.id == aiChat.id) {
					hasAiChat = true;
					list[key] = aiChat;
				}
			});

			if(!hasAiChat) list.push(aiChat);
			commit('setList', list);
		},
		removeChat({ commit, state }, id) {
			var list = _.clone(state.list),
				index = _.findIndex(list, { id: id });

			if(index !== -1) {
				list.splice(index, 1);
				commit('setList', list);
			}
		},
		addMessage({ commit, state }, message) {
			var list = _.clone(state.list),
				aiChat = _.find(list, { id: message.ai_chat_id });

			if(aiChat) {
				aiChat.messages.push(message);

				_.forEach(list, (o, key) => {
					if(o.id == aiChat.id) list[key] = aiChat;
				});

				commit('setList', list);
			}
		}
	},
	mutations: {
		setList(state, list) {
			state.list = list;
		}
	}
}
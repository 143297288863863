<template>
	<div class="modal" id="modal-status" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content shadow-3" v-if="isDefined">
				<div class="modal-header">
					<store-card-small :store="current_store" :subtitle="current_store.description" />
				</div>
				<div class="modal-body p-0">
					<div class="list-group list-group-flush">
						<div class="list-group-item d-flex align-items-center px-6 py-3" v-if="current_store.type == 'store' && allowImmediateRequests">
							<div class="flex-fill">
								<div class="text-sm fw-bold">El comercio se encuentra abierto</div>
								<div class="text-sm" v-html="delay_show"></div>
							</div>
							<i class="bi bi-clock-history text-lg"></i>
						</div>
						<div class="list-group-item d-flex align-items-center px-6 py-3" v-if="current_store.type == 'store' && !allowImmediateRequests">
							<div class="flex-fill">
								<div class="text-sm fw-bold">El comercio se encuentra cerrado</div>
								<div class="text-sm">No está entregando pedidos ahora.</div>
							</div>
							<i class="bi bi-lock text-lg"></i>
						</div>
						<div class="list-group-item d-flex align-items-center px-6 py-3" v-if="current_store.type == 'store' && allowScheduledRequests">
							<div class="flex-fill">
								<div class="text-sm fw-bold">Permite programar pedidos</div>
								<div class="text-sm">Podés programarlos para otro momento.</div>
							</div>
							<i class="bi bi-calendar3 text-lg"></i>
						</div>
						<div class="list-group-item d-flex align-items-center px-6 py-3" v-for="(hour, day) in current_store.hours" v-bind:key="day">
							<div class="flex-fill">
								<!-- Title -->
								<div class="d-block h6" :class="{'text-success': dayCurrent(hour.day_idx), 'text-dark-50': !dayCurrent(hour.day_idx)}">{{ dayName(hour.day_idx) }}</div>
								<!-- Status -->
							</div>
							<div class="">
								<p class="p-0 m-0" :class="{'text-success': dayCurrent(hour.day_idx), 'text-dark-50': !dayCurrent(hour.day_idx)}" v-for="(time, key) in hour.hours" v-bind:key="key">De {{ time.from.substring(0, 5) }} a {{ time.to.substring(0, 5) }} hs</p>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer justify-content-between">
					<a target="_blank" v-bind:href="'https://wa.me/'+current_store.phone" class="btn btn-sm btn-secondary" v-if="!!current_store.phone">Contactar</a>
					<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Cerrar</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed, onMounted } from 'vue';
	import { Modal } from 'bootstrap';
	import _ from 'lodash';
	import composableStore from '@/composables/store';

	const DAYS = {
		'1': 'Lunes',
		'2': 'Martes',
		'3': 'Miércoles',
		'4': 'Jueves',
		'5': 'Viernes',
		'6': 'Sábado',
		'7': 'Domingo'
	}

	export default {
		setup() {
			const { isDefined, current_store, allowImmediateRequests, allowScheduledRequests } = composableStore();

			const delay_show = computed(() => {
				return `${(current_store.value.delay_show == 0) ? 'No tiene demoras.' : `Tiene <b>${current_store.value.delay_show} minutos</b> de demora.`}`;
			});

			const hasHours = computed(() => {
				return current_store.value.hours.length > 0;
			});

			function dayCurrent(day) {
				var current = new Date();
				if (day == current.getDay())
					return true;

				return false;
			}

			function dayName(day) {
				if (_.has(DAYS, day))
					return DAYS[day];

				return null;
			}

			onMounted(() => new Modal(document.getElementById('modal-status')));

			return { isDefined, current_store, allowImmediateRequests, allowScheduledRequests, delay_show, hasHours, dayCurrent, dayName };
		}
	}
</script>
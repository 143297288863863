import moment from 'moment';
import _ from 'lodash';
import VueScrollTo from 'vue-scrollto';

export default {
	namespaced: true,
	state: {
		slug: null,
		type: null,
		when: null,
		payment: null,
		comments: null,
		promocode: null,
		items: [],
		subtotal: 0,
		discount: 0,
		delivery_amount: 0,
		total: 0
	},
	getters: {
		isDefined(state) {
			return !!state.slug;
		},
		isEmpty(state) {
			return (state.items.length === 0);
		},
		slug(state) {
			return state.slug;
		},
		type(state) {
			return state.type;
		},
		when(state) {
			return state.when;
		},
		payment(state) {
			return state.payment;
		},
		comments(state) {
			return state.comments;
		},
		promocode(state) {
			return state.promocode;
		},
		hasPromocode(state) {
			return (state.promocode != null);
		},
		items(state) {
			return state.items;
		},
		itemTotalByIndex: (state) => (index) => {
			var total = 0,
				item = state.items[index];

			if(!item) return 0;

			total = item.unit_price;
			_.forEach(item.additionals, (additional) => { total+= additional.unit_price * additional.quantity; });

			return (total * item.quantity) - ((item.discount) ? item.discount : 0);
		},
		subtotal(state) {
			return state.subtotal;
		},
		discount(state) {
			return state.discount;
		},
		deliveryAmount(state) {
			return state.delivery_amount;
		},
		total(state) {
			return state.total;
		}
	},
	actions: {
		empty({ dispatch }) {
			dispatch('setSlug', null);
			dispatch('setType', null);
			dispatch('setComments', null);
			dispatch('setPromocode', null);
			dispatch('setItems', []);
		},
		setSlug({ commit }, slug) {
			commit('setSlug', slug);
		},
		setItems({ dispatch, commit, rootGetters }, new_items = []) {
			var discounts = rootGetters['store/discounts'];
			var catalogType = (rootGetters['code/isDefined']) ? rootGetters['code/type'] : 'ONLINE';
			var items_original = _.clone(new_items);
			var items = _.clone(new_items);
			var items_remaining = [];

			//Establece el índice
			_.forEach(items, (item, index) => { item.index = index; });
			items = _.sortBy(items, [function(item) {
				var total = item.unit_price;
				_.forEach(item.additionals, (additional) => { total+= additional.unit_price * additional.quantity; });
				return total * -1;
			}]);

			_.forEach(items, (item) => {
				var discount = _.find(discounts, (data) => {
					if(_.indexOf(data.catalogs, catalogType) === -1) return false;
					if(data.from && moment(data.from).isAfter(moment())) return false;
					if(data.to && moment(data.to).isBefore(moment())) return false;
					if(data.days && _.indexOf(data.days, moment().day()) === -1) return false;

					return (data.target == null || _.indexOf(data.target, item.id) != -1);
				});
				items_original[item.index].discount = 0;

				if(discount) {
					var unit_total = item.unit_price;
					_.forEach(item.additionals, (additional) => { unit_total+= additional.unit_price * additional.quantity; });

					switch(discount.type) {
						case 'PERCENTAGE':
							items_original[item.index].discount = Math.round(((unit_total * item.quantity) * (discount.value / 100)) * 100) / 100;
							break;
						case 'PRODUCTS':
							var combination = null;
							switch(discount.products_combination) {
								case 'SAME': combination = `${discount.id}-${item.id}`; break;
								case 'VALUE': combination = `${discount.id}-${item.unit_price}`; break;
								case 'ANY': combination = `${discount.id}`; break;
							}

							if(!(combination in items_remaining)) items_remaining[combination] = 0;

							var quantity_computed = item.quantity + items_remaining[combination];
							var factor = Math.trunc(quantity_computed / discount.products_from);
							var free = factor * (discount.products_from - discount.products_to);

							if(free) items_original[item.index].discount = free * unit_total;
							items_remaining[combination] = quantity_computed - (discount.products_from * factor);
							break;
					}
				}
			});

			commit('setItems', items_original);
			dispatch('calculateSubtotal');
		},
		setType({ dispatch, commit }, type) {
			commit('setType', type);
			dispatch('setWhen', null);

			if(document.getElementById(`checkout-when`)) {
				setTimeout(() => {
					VueScrollTo.scrollTo(`#checkout-when`, 100, {offset: () => { return (document.getElementById('total-description').clientHeight + 10) * -1; }});
				}, 500);
			}
		},
		setWhen({ dispatch, commit }, when) {
			commit('setWhen', when);
			dispatch('setPayment', null);

			if(document.getElementById(`checkout-payment`)) {
				setTimeout(() => {
					VueScrollTo.scrollTo(`#checkout-payment`, 100, {offset: () => { return (document.getElementById('total-description').clientHeight + 10) * -1; }});
				}, 500);
			}
		},
		setPayment({ dispatch, commit }, payment) {
			commit('setPayment', payment);
			dispatch('calculateSubtotal');

			if(document.getElementById(`checkout-comments`)) {
				setTimeout(() => {
					VueScrollTo.scrollTo(`#checkout-comments`, 100, {offset: () => { return (document.getElementById('total-description').clientHeight + 10) * -1; }});
				}, 500);
			}
		},
		setComments({ commit }, comments) {
			commit('setComments', comments);
		},
		setPromocode({ dispatch, commit }, promocode) {
			commit('setPromocode', promocode);
			dispatch('setPayment', null);
		},
		addItem({ dispatch, state }, item) {
			var items = _.clone(state.items);

			//Busca un item igual
			var has_item = false;
			if(!item.comments) _.forEach(items, (current) => {
				if(!has_item && !current.comments && _.isEqual(_.pick(item, ['id', 'additionals']), _.pick(current, ['id', 'additionals']))) {
					current.quantity+= item.quantity;
					has_item = true;
				}
			});

			if(!has_item) items.push(item);

			dispatch('setItems', items);
			dispatch('setType', null);
		},
		subItem({ dispatch, state }, id) {
			var items = _.clone(state.items);
			var index = _.findIndex(items, function(data) { return (data.id == id); });

			if(items[index].quantity > 1) items[index].quantity-= 1;
			else items.splice(index, 1);

			dispatch('setItems', items);
			if(items.length == 0) dispatch('empty');
			else dispatch('setType', null);
		},
		deleteItem({ dispatch, state }, index) {
			var items = _.clone(state.items);
			items.splice(index, 1);

			dispatch('setItems', items);
			if(items.length == 0) dispatch('empty');
			else dispatch('setType', null);
		},
		calculateSubtotal({ state, dispatch, commit, getters }) {
			var subtotal = 0;
			_.forEach(state.items, (item, key) => { subtotal+= getters.itemTotalByIndex(key); });

			commit('setSubtotal', subtotal);
			dispatch('calculateDiscount');
		},
		calculateDiscount({ state, dispatch, commit }) {
			var discount = 0;

			if(state.promocode) switch(state.promocode.type) {
				case 'PERCENTAGE':
					discount = Math.round((state.subtotal * (state.promocode.value / 100)) * 100) / 100;
					break;
				case 'AMOUNT':
					discount = (state.subtotal < state.promocode.value) ? state.subtotal : state.promocode.value;
					break;
			}

			commit('setDiscount', discount);
			dispatch('calculateDeliveryAmount');
		},
		calculateDeliveryAmount({ state, dispatch, commit }) {
			var delivery_amount = (state.type && state.type.option == 'DELIVERY') ? state.type.amount : 0;

			commit('setDeliveryAmount', delivery_amount);
			dispatch('calculateTotal');
		},
		calculateTotal({ state, commit }) {
			var total = state.subtotal - state.discount + state.delivery_amount;

			commit('setTotal', total);
		}
	},
	mutations: {
		setSlug(state, slug) {
			state.slug = slug;
		},
		setType(state, type) {
			state.type = type;
		},
		setWhen(state, when) {
			state.when = when;
		},
		setPayment(state, payment) {
			state.payment = payment;
		},
		setComments(state, comments) {
			state.comments = comments;
		},
		setPromocode(state, promocode) {
			state.promocode = promocode;
		},
		setItems(state, items) {
			state.items = items;
		},
		setSubtotal(state, subtotal) {
			state.subtotal = subtotal;
		},
		setDiscount(state, discount) {
			state.discount = discount;
		},
		setDeliveryAmount(state, delivery_amount) {
			state.delivery_amount = delivery_amount;
		},
		setTotal(state, total) {
			state.total = total;
		}
	}
}
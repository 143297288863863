<template>
	<div class="vstack gap-2">
		<div class="hstack gap-2 scrollable-x ms-n6 ps-18">
			<assistant-suggesteds-stores-card v-for="store in (suggested?.stores ?? [])" v-bind:key="store.id" :store="store" @select="selectStore" />
			<assistant-suggesteds-items-card v-for="item in (suggested?.items ?? [])" v-bind:key="item.id" :item="item" @select="selectItem" />
		</div>
		<div class="hstack gap-3 ps-12">
			<div class="badge rounded-2 text-dark bg-white">Sugeridos</div>
		</div>
	</div>
</template>

<script>
	export default {
		emits: ['selectStore', 'selectItem'],
		props: {
			suggested: Object
		},
		setup(props, { emit }) {
			const selectStore = (data) => emit('selectStore', data);
			const selectItem = (data) => emit('selectItem', data);

			return { selectStore, selectItem };
		}
	}
</script>
<template>
	<transition name="block" mode="out-in">
		<div class="d-flex h-100 justify-content-center align-items-center" v-if="show">
			<div class="d-flex align-items-center" v-if="theme == 'default'">
				<img :src="logo" alt="logo">
				<h2 class="ms-3">{{ text }}</h2>
			</div>
			<div class="spinner-grow text-dark" style="width: 3rem; height: 3rem;" role="status" v-else-if="theme == 'spinner'">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>
	</transition>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';

	export default {
		props: {
			theme: {
				type: String,
				default: 'default'
			},
			text: String
		},
		setup(props) {
			const show = ref(false);

			const logo = computed(() => {
				if(props.text) return require('@/assets/img/logo/dark-simple.png');
				return require('@/assets/img/logo/dark-complete.png');
			});

			onMounted(() => {
				show.value = true;
			});

			return { show, logo };
		}
	}
</script>

<style scoped>
	img {
		display: block;
		max-height:45px;
		width: auto;
		height: auto;
	}

	.block-enter-from {
		opacity: 0;
	}

	.block-enter-to {
		opacity: 1;
	}

	.block-enter-active, .block-leave-active {
		transition: all 3s ease;
	}
</style>
import { computed } from 'vue';
import store from '@/store';
import router from '@/router';
import { useRoute } from 'vue-router';
import _ from 'lodash';
import VueScrollTo from 'vue-scrollto';
import composableStore from '@/composables/store';
import composableForm from '@/composables/form';

export default function() {
	const { params: { slug } } = useRoute();
	const { showStatus } = composableStore();
	const { catchError } = composableForm();

	const items = computed(() => store.getters['cart/items']);

	const canOrder = computed(() => {
		//Espera a que esten cargados los servicios.
		if(!store.getters['store/services']) return false;
		else return store.getters['store/allowOrderRequests'];
	});

	const canProceed = computed(() => {
		//Espera a que esten cargados los servicios.
		if(!store.getters['store/services']) return false;
		else return (store.getters['store/allowOrderRequests'] && !store.getters['cart/isEmpty']);
	});

	const cartTotal = computed(() => store.getters['cart/total']);

	const addItem = (item, quantity, selection, comments, inDetail) => {
		if(store.getters['cart/isDefined'] && store.getters['cart/slug'] != slug) {
			router.push({name: 'cart.detail'});
		}

		if(!canOrder.value) {
			showStatus();
			return false;
		}

		if(!validateItem(item, selection, inDetail)) {
			return false;
		}

		var itemCart = {
			id: item.id,
			name: item.name,
			quantity: quantity,
			unit_price: item.price,
			discount: 0,
			comments: comments,
			additionals: []
		};

		_.forEach(selection, (group) => {
			_.forEach(group.additionals, (additional) => {
				var additionalSelection = JSON.parse(JSON.stringify(additional));
				additionalSelection.group_id = group.group_id;
				itemCart.additionals.push(additionalSelection);
			});
		});

		store.dispatch('cart/setSlug', slug);
		store.dispatch('cart/addItem', itemCart).catch(catchError);

		return true;
	}

	const validateItem = (item, selection, inDetail) => {
		var valid = true,
			requiredGroups = [],
			first_error_group_id = null;

		//Obtiene todos los grupos obligatorios.
		requiredGroups = _.filter(item.groups, (group) => { return group.constraints.min > 0 });
		requiredGroups = _.map(requiredGroups, (group) => { return group.id; });

		//Checkea que haya selecciones para todos los grupos obligatorios
		_.forEach(requiredGroups, (group_id) => {
			let exists = _.find(selection, (selected) => { return selected.group_id == group_id; });

			if(!exists) {
				valid = false;
				if(!first_error_group_id) first_error_group_id = group_id;
			}
		});

		if(!valid) {
			if(inDetail) VueScrollTo.scrollTo(`#group-${first_error_group_id}`, 100, {offset: () => { return (document.getElementById('item-description').clientHeight + 10) * -1; }});
			return valid;
		}

		//Ya tiene todos los grupos seleccionados. Ahora verifico que sus selecciones sean válidas.
		_.forEach(selection, (selected) => {
			if(!selected.satisfy) {
				valid = false;
				if(!first_error_group_id) first_error_group_id = selected.group_id;
			}
		});

		if(!valid && inDetail) VueScrollTo.scrollTo(`#group-${first_error_group_id}`, 100, {offset: () => { return (document.getElementById('item-description').clientHeight + 10) * -1; }});
		return valid;

	}

	return { items, canOrder, canProceed, cartTotal, addItem, validateItem };
}
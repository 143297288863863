<template>
	<a href="javascript:void(0);" class="avatar rounded-circle" :class="{ 'avatar-new': avatarNew }" @click="showStories">
		<img :src="store.logo_url" alt="logo">
	</a>
	<store-stories-view :store="store" :storiesVisible="storiesVisible" @setVisible="setVisible" />
</template>

<script>
	import { ref, computed } from 'vue';
	import store from '@/store';
	import _ from 'lodash';

	export default {
		props: {
			store: Object
		},
		setup(props) {
			const vieweds = computed(() => store.getters['interaction/stories']);
			const avatarNew = computed(() => {
				var hasNew = _.find(props.store.stories_active, function(data) { return (_.indexOf(vieweds.value, data.id) == -1); });
				return !!hasNew;
			});

			const storiesVisible = ref(false);
			const showStories = () => storiesVisible.value = !storiesVisible.value;
			const setVisible = (visible) => storiesVisible.value = visible;

			return { avatarNew, storiesVisible, showStories, setVisible };
		}
	}
</script>

<style scoped>
	.avatar {
		border: 2px solid #c3c6cb !important;
		padding: 2px;
		width: 3.75rem;
		height: 3.75rem;
	}

	.avatar-new {
		border-color: #00EE00 !important;
	}
</style>
<template>
	<div class="fixed-bottom bg-white" v-if="canProceed">
		<div class="container py-4">
			<div class="row">
				<div class="offset-md-4 col-12 col-md-4">
					<div class="d-grid gap-2">
						<router-link :to="{name: 'cart.detail'}" class="btn btn-dark">Ver mi pedido {{ vueNumberFormat(cartTotal, $personalizer.currency.value) }}</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import composableCheckout from '@/composables/checkout';

	export default {
		setup() {
			const { canProceed, cartTotal } = composableCheckout();

			return { canProceed, cartTotal };
		}
	}
</script>
<template>
	<a href="javascript:void(0);" class="hstack gap-3 text-limit text-inherit" @click="scrollTop">
		<div class="avatar rounded" v-if="!isDefined || store.logo_url">
			<img :src="store.logo_url" alt="logo" @load="logoReady = true" v-show="logoReady">
			<Skeletor width="100%" height="100%" class="rounded" v-show="!logoReady" />
		</div>
		<div class="overflow-hidden">
			<span class="fw-semibold h4 d-block text-truncate" style="color: inherit;">{{ store.name }}</span>
			<Skeletor class="fw-semibold h4" v-show="!isDefined" />
			<small class="d-block text-truncate">
				{{ description() }}
				<span class="ms-1 fw-semibold" v-if="store?.address?.distance">{{ distanceDescription() }}</span>
			</small>
		</div>
	</a>
</template>

<script>
	import { ref, computed } from 'vue';
	import VueScrollTo from 'vue-scrollto';

	export default {
		props: {
			store: Object
		},
		setup(props) {
			const isDefined = computed(() => !!props.store.id);
			const logoReady = ref(false);

			const description = () => {
				if(props.store.description) return props.store.description;
				else if(props.store?.address?.small) return props.store.address.small;
				else null;
			}

			const scrollTop = () => VueScrollTo.scrollTo('body', 100);

			return { isDefined, logoReady, description, scrollTop };
		}
	}
</script>

<style scoped>
	.avatar {
		min-width: 2.875rem !important;
		min-height: 2.875rem !important;
	}
</style>
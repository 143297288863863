<template>
	<router-link :to="{name: 'code.scan'}" class="d-inline-flex align-items-center rounded-pill bg-dark">
		<div class="ps-2 pe-1">
			<span class="h6 text-sm text-white fw-semibold">{{ description }}</span>
		</div>
		<div>
			<span class="avatar avatar-sm bg-dark rounded-circle border-2 border-dark">
				<img src="@/assets/img/icons/qr.jpg" alt="Qr Scan">
			</span>
		</div>
	</router-link>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';

	export default {
		setup() {
			const description = computed(() => {
				if(!store.getters['code/isDefined']) return 'Escaneá';
				else return store.getters['code/description'];
			});

			return { description };
		}
	}
</script>
<template>
	<a href="javascript:void(0);" @click="select">
		<div class="avatar avatar-lg avatar-background" :style="{'background-image': `url(${store.logo_url})`}" v-if="imageReady"></div>
		<Skeletor height="100%" class="avatar avatar-xl avatar-background" v-if="!imageReady" />
	</a>
</template>

<script>
	import { ref, onMounted } from 'vue';

	export default {
		emits: ['select'],
		props: {
			store: Object
		},
		setup(props, { emit }) {
			const imageReady = ref(false);

			onMounted(() => {
				var loadImage = new Image();
				loadImage.src = props.store.logo_url;
				loadImage.onload = function() { imageReady.value = true; };
			});

			const select = () => emit('select', props.store);

			return { imageReady, select };
		}
	}
</script>

<style scoped>
	.avatar {
		background-size: cover !important;
		background-position: center !important;
	}
</style>
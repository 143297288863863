import axios from 'axios';
import store from '@/store';

const baseAPI = (process.env.NODE_ENV === 'production') ? `${window.location.origin}/api` : `${process.env.VUE_APP_URL}/api`;
const instance = axios.create({
	baseURL: baseAPI,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		'Cache-Control': 'no-cache'
	}
});

instance.interceptors.request.use((config) => {
	let accessToken = store.getters['auth/token'];
	if(accessToken !== null) config.headers['Authorization'] = `${accessToken.token_type} ${accessToken.access_token}`;

	return config;
});

export default instance;
import { computed } from 'vue';
import store from '@/store';
import router from '@/router';

export default function() {
	const redirectBackInfo = computed(() => store.getters['redirect/back']);
	const redirectNextInfo = computed(() => store.getters['redirect/next']);

	const redirectBack = () => {
		if(!redirectBackInfo.value) return router.push({ name: 'home' });
		else if(redirectBackInfo.value.path == router.options.history.state.back) return router.go(-1);
		else return router.push({ path: redirectBackInfo.value.path });
	}

	const redirectNext = () => {
		if(!redirectNextInfo.value) return redirectBack();
		else if(redirectNextInfo.value.path == router.options.history.state.back) return router.go(-1);
		else return router.push({ path: redirectNextInfo.value.path });
	}

	return { redirectBackInfo, redirectNextInfo, redirectBack, redirectNext };
}
<template>
	<div class="position-sticky top-0 z-2 border-bottom bg-light">
		<div class="container py-3 z-2">
			<div class="hstack gap-3 align-items-center">
				<a href="javascript:void(0);" class="text-muted" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseSidebarRight" @click="redirectBack">
					<i class="bi bi-chevron-left"></i>
				</a>
				<img :src="logo" class="w-rem-10 img-fluid rounded-circle">
				<div class="overflow-hidden" v-if="type == 'general'">
					<h5 class="text-truncate">Asistente virtual</h5>
					<p class="text-sm text-muted text-truncate">Consultas generales</p>
				</div>
				<div class="overflow-hidden" v-if="type == 'store'">
					<h5 class="text-truncate">{{ store.name }}</h5>
					<p class="text-sm text-muted text-truncate">Consultas sobre la tienda</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';

	export default {
		emits: ['redirectBack'],
		props: {
			type: String,
			store: Object
		},
		setup(props, { emit }) {
			const redirectBack = () => emit('redirectBack');

			const logo = computed(() => {
				if(props.store?.logo_url) return props.store.logo_url;
				return require('@/assets/img/ai/head.jpg');
			});

			return { redirectBack, logo };
		}
	}
</script>
<template>
	<router-link :to="{ name: 'identify.index' }" class="avatar avatar-sm text-inherit" v-if="!user">
		<i class="bi bi-person-circle h1 text-inherit"></i>
	</router-link>
	<a href="javascript:void(0);" class="d-block" @click="openMenu" v-if="user">
		<div class="avatar avatar-sm rounded-circle" v-show="imageReady">
			<img :src="avatar" @load="imageReady = true" alt="Avatar">
		</div>
		<Skeletor width="36" height="36" class="avatar-sm rounded-circle text-white ms-0" v-show="!imageReady" />
	</a>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import { Offcanvas } from 'bootstrap';

	export default {
		props: {
			user: Object
		},
		setup(props) {
			const imageReady = ref(false);

			const avatar = computed(() => {
				if(props.user.avatar) return props.user.avatar;
				return require('@/assets/img/icons/avatar.jpg');
			});

			//Offcanvas de menú de usuario
			const userMenu = ref(null);
			onMounted(() => {
				if(props.user) userMenu.value = new Offcanvas(document.getElementById('user-menu'), {scroll: true})
			});
			const openMenu = () => userMenu.value.show();

			return { avatar, imageReady, openMenu };
		}
	}
</script>

<template>
	<teleport to="body">
		<div class="modal" id="user-status-modal-logout" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content shadow-3">
					<div class="modal-body text-center vstack gap-2">
						<i class="bi bi-person-fill-exclamation text-danger" style="font-size: 3.5rem;"></i>
						<h3>¿Querés salir de tu cuenta?</h3>
						<p>Tendrás que volver a iniciar sesión para realizar un nuevo pedido o hacer consultas sobre tus pedidos ya realizados.</p>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Cancelar</button>
						<button class="btn btn-sm btn-danger d-flex align-items-center" @click="logout">
							Cerrar sesión
							<span class="icon icon-sm ps-2">
								<i class="bi bi-box-arrow-right"></i>
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import store from '@/store';
	import { Modal } from 'bootstrap';

	export default {
		setup() {
			const modal = ref(null);
			onMounted(() => modal.value = new Modal(document.getElementById('user-status-modal-logout')));

			const logout = () => {
				modal.value.hide();
				store.dispatch('auth/logout');
			}

			return { logout };
		}
	}
</script>
<template>
	<div :id="`section-${section.id}`" :section-id="section.id" class="section-content">
		<h3 class="progress-text mb-4 d-block">{{ section.name }}</h3>
		<div class="row g-6">
			<catalog-item-card v-for="item in items" v-bind:key="item.id" :item="item"></catalog-item-card>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import _ from 'lodash';
	import composableStore from '@/composables/store';

	export default {
		props: {
			section: Object
		},
		setup(props) {
			const { catalog } = composableStore();

			const items = computed(() => _.filter(catalog.value.items, (row) => { return row.section_id === props.section.id; }));

			return { items };
		}
	}
</script>
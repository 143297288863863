import Repository from "./Repository";

const resource = "/addresses";

export default {
	create(payload) {
		return Repository.post(`${resource}`, payload);
	},
	list() {
		return Repository.get(resource);
	},
	destroy(id) {
		var endpoint = resource.concat('/:id').replace(':id', id);
		return Repository.delete(endpoint);
	},
	default() {
		return Repository.get(`${resource}/default`);
	},
	select(id) {
		var endpoint = resource.concat('/:id').replace(':id', id);
		return Repository.get(endpoint);
	}
}
<template>
	<img src="@/assets/img/logo/main.png" class="h-rem-12" alt="Logo" v-if="!isDefined">
	<img :src="microsite.store.logo_url" class="avatar-xl rounded rounded-3" alt="Logo" v-else-if="microsite.store.logo_url">
	<div class="fw-semibold h2 d-block text-truncate" v-else>{{ microsite.store.name }}</div>
</template>

<script>
	import composableMicrosite from '@/composables/microsite';

	export default {
		setup() {
			const { isDefined, microsite } = composableMicrosite();

			return { isDefined, microsite };
		}
	}
</script>
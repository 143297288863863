<template>
	<div class="w-100 h-100">
		<router-view v-slot="{ Component }">
			<transition name="route" mode="out-in">
				<component :is="Component" />
			</transition>
		</router-view>
		<store-status />
		<store-info />
	</div>
</template>

<script>
	import { ref, watch, onBeforeMount, onBeforeUnmount } from 'vue';
	import { useRoute } from 'vue-router';
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const { init, load } = composableStore();
			const route = useRoute();
			const interval = ref(null);

			const loadStore = (slug) => {
				if(slug) load(slug);
				if(interval.value) clearInterval(interval.value);
				interval.value = setInterval(() => init(route.params.slug), (300 * 1000));
			}

			onBeforeMount(() => loadStore(route.params.slug));
			onBeforeUnmount(() => { if(interval.value) clearInterval(interval.value); });
			watch(() => route.params.slug, loadStore);
		}
	}
</script>
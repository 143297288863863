import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import auth from './modules/auth';
import interaction from './modules/interaction';
import location from './modules/location';
import marketing from './modules/marketing';
import code from './modules/code';
import microsite from './modules/microsite';
import store from './modules/store';
import stores from './modules/stores';
import cart from './modules/cart';
import redirect from './modules/redirect';
import aiChats from './modules/aiChats';

const vuexLocalStorage = new VuexPersistence({
	storage: window.localStorage,
	modules: ['auth', 'location', 'interaction']
});

const vuexSessionStorage = new VuexPersistence({
	storage: window.sessionStorage,
	modules: ['cart', 'code', 'marketing', 'redirect', 'store', 'stores', 'aiChats']
});

export default createStore({
	plugins: [
		vuexLocalStorage.plugin,
		vuexSessionStorage.plugin
	],
	modules: {
		auth: auth,
		interaction: interaction,
		location: location,
		code: code,
		marketing: marketing,
		microsite: microsite,
		store: store,
		stores: stores,
		cart: cart,
		redirect: redirect,
		aiChats: aiChats
	}
})

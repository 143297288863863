<template>
	<div class="list-group-item px-0">
		<div class="d-flex">
			<div class="pe-2 h5">{{ item.quantity }}x</div>
			<div class="flex-fill">
				<div class="d-flex h5">
					<div class="flex-fill">{{ item.name }}</div>
					<div class="flex-fill text-nowrap text-end ps-2">{{ vueNumberFormat(total, $personalizer.currency.value) }}</div>
				</div>
				<div class="d-flex">
					<div class="flex-fill">
						<p class="text-muted">{{ additionalsAsString }}</p>
						<p class="text-warning">{{ item.comments }}</p>
					</div>
					<div class="flex-fill text-nowrap text-end ps-5">
						<small class="text-danger" v-if="item.discount > 0">{{ vueNumberFormat(discountPercentage(), { prefix: '', suffix: '% OFF' }) }}</small>
						<a href="javascript:void(0);" class="text-danger fw-semibold ms-3" @click="deleteDialog" v-if="deleteShow">Quitar</a>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal" :id="`modal-delete-${index}`" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
		<div class="modal-dialog modal-sm modal-dialog-centered">
			<div class="modal-content shadow-4">
				<div class="modal-body text-center">
					<h4 class="mb-1">¿Quitar producto del carrito?</h4>
					<p class="text-sm text-muted">{{ item.name }}</p>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Cancelar</button>
					<button type="button" class="btn btn-sm btn-danger" @click="deleteItem">Quitar</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import store from '@/store';
	import { Modal } from 'bootstrap';
	import _ from 'lodash';

	export default {
		props: {
			item: Object,
			index: Number,
			deleteShow: {
				type: Boolean,
				default: true
			}
		},
		setup(props) {
			const additionalsAsString = computed(() => {
				var all = [];
				_.forEach(props.item.additionals, (additional) => { all.push(`${additional.name} x ${additional.quantity.toString()}`); });
				return all.join(', ');
			});

			const total = computed(() => store.getters['cart/itemTotalByIndex'](props.index));

			//Modal de eliminación del producto
			const modal = ref(null);
			onMounted(() => modal.value = new Modal(document.getElementById(`modal-delete-${props.index}`)));
			const deleteDialog = () => modal.value.show();
			const deleteItem = () => {
				store.dispatch('cart/deleteItem', props.index);
				modal.value.hide();
			}

			const discountPercentage = () => {
				var item_total = props.item.unit_price * props.item.quantity;
				_.forEach(props.item.additionals, (additional) => { item_total+= additional.unit_price * additional.quantity * props.item.quantity; });

				return ('discount' in props.item) ? Math.round((props.item.discount * 100 / item_total) * 100) / 100 : 0;
			}

			return { additionalsAsString, total, discountPercentage, deleteDialog, deleteItem };
		}
	}
</script>

<style scoped="">
</style>
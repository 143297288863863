<template>
	<div class="py-7 pt-md-24">
		<div class="container mw-screen-xl p-0">
			<div class="row gy-8 align-items-center justify-content-between">
				<div class="col-12 col-md-6 col-lg-5">
					<!-- Images -->
					<div class="row g-4">
						<div class="col-6 vstack gap-4">
							<img :src="require(`@/assets/img/banners/home-01.jpg`)" class="img-fluid rounded-3 border border-2 border-dark">
							<img :src="require(`@/assets/img/banners/home-02.jpg`)" class="img-fluid rounded-3 border border-2 border-dark">
						</div>
						<div class="col-6 vstack gap-4">
							<img :src="require(`@/assets/img/banners/home-03.jpg`)" class="img-fluid rounded-3 border border-2 border-dark">
							<img :src="require(`@/assets/img/banners/home-04.jpg`)" class="img-fluid rounded-3 border border-2 border-dark">
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-7 text-center" data-aos="fade-left">
					<!-- Heading -->
					<h1 class="ls-tight fw-bolder mb-4">
						¡Bienvenido a Wantti!
					</h1>
					<!-- Text -->
					<p class="lead text-muted mb-6">
						Para acceder al listado de tiendas <span class="d-inline-block">debemos saber tú ubicación.</span>
					</p>
					<!-- Links -->
					<div class="mb-4">
						<router-link :to="{name: 'location.index'}" class="btn btn-dark" v-if="hasUser">Mis direcciones</router-link>
						<router-link :to="{name: 'location.create'}" class="btn btn-dark" v-else>Establecer ubicación</router-link>
					</div>
					<div v-if="!hasUser">
						¿Ya tienes una cuenta? <router-link :to="{name: 'identify.index'}" class="text-secondary fw-semibold">Identificate</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';

	export default {
		setup() {
			const hasUser = computed(() => store.getters['auth/hasUser']);

			return { hasUser };
		}
	}
</script>
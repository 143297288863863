<template>
	<nav class="container navbar px-0 py-3 overflow-hidden fixed-top" v-bind:class="{ 'header-shadow': !isOnTop }">
		<transition name="slide" mode="out-in">
			<div class="container d-flex align-items-center" v-if="!showStore">
				<!-- Brand -->
				<router-link :to="{name: 'home'}" class="navbar-brand" v-if="!isDefined">
					<img src="@/assets/img/logo/main.png" alt="Logo">
				</router-link>
				<router-link :to="{name: 'home'}" class="navbar-microsite" v-else-if="microsite.logo_url">
					<img :src="microsite.logo_url" alt="Logo">
				</router-link>
				<store-card-header :store="microsite.store" v-else />
				<!-- Search -->
				<div class="ms-auto d-none d-md-block" style="max-width: 50%;">
					<user-location-button :styles="styles" />
				</div>
				<!-- User -->
				<div class="ms-4">
					<user-status-index />
				</div>
			</div>
			<div class="container d-block" v-else>
				<store-card-header :store="store" />
			</div>
		</transition>
	</nav>
</template>

<script>
	import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
	import composableMicrosite from '@/composables/microsite';

	export default {
		props: {
			store: Object,
			styles: Object
		},
		setup(props) {
			const { isDefined, microsite } = composableMicrosite();
			const isOnTop = ref(true);
			const showStore = ref(false);
			const theme_color = ref(null);

			onMounted(() => {
				window.addEventListener('scroll', handleScroll);
				theme_color.value = document.querySelector('meta[name="theme-color"]').content;
				if(props.styles?.header?.background) document.querySelector('meta[name="theme-color"]').content = props.styles.header.background;
			});

			onBeforeUnmount(() => {
				window.removeEventListener('scroll', handleScroll);
				if(props.styles?.header?.background) document.querySelector('meta[name="theme-color"]').content = theme_color.value;
			});

			const handleScroll = () => {
				isOnTop.value = window.pageYOffset == 0;
				if(props.store) showStore.value = (window.pageYOffset >= (document.getElementById('store-body').offsetTop - 70));
			}

			const background = computed(() => {
				if(props.styles?.header?.background) return props.styles.header.background;
				else return '#fcfcfc';
			});

			const color = computed(() => {
				if(props.styles?.header?.color) return props.styles.header.color;
				else return '#16192c';
			});

			return { isDefined, microsite, isOnTop, showStore, background, color };
		}
	}
</script>

<style scoped>
	.navbar {
		background-color: v-bind(background);
		color: v-bind(color);
		height: 70px;
		max-height: 70px;
	}

	.navbar-brand {
		padding-top: .4rem !important;
		padding-bottom: .4rem !important;
	}

	.navbar-brand img {
		height: 2.2rem !important;
	}

	.navbar-microsite {
		padding-top: .1rem !important;
		padding-bottom: .1rem !important;
	}

	.navbar-microsite img {
		max-height: 2.8rem !important;
		max-width: 12rem;
	}

	.navbar {
		transition: box-shadow 0.3s;
	}

	.slide-enter-active, .slide-leave-active {
		transition: all 0.1s ease-out;
	}

	.slide-enter-from, .slide-leave-to {
		transform: translateY(-100%);
	}
</style>
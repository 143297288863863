import { computed, watch } from 'vue';
import store from '@/store';
import { Modal } from 'bootstrap';
import _ from 'lodash';

export default function() {
	const isDefined = computed(() => store.getters['store/isDefined']);

	const current_store = computed(() => {
		if(isDefined.value) return store.getters['store/info'];
		return { id: null, slug: null, name: null, logo: null, logo_url: null, cover: null, address: { complete: null } };
	});

	const services = computed(() => {
		if(isDefined.value) return store.getters['store/services'];
		return false;
	});

	const allowImmediateRequests = computed(() => {
		if(isDefined.value) return store.getters['store/allowImmediateRequests'];
		return false;
	});

	const allowScheduledRequests = computed(() => {
		if(isDefined.value) return store.getters['store/allowScheduledRequests'];
		return false;
	});

	const init = (slug) => {
		store.dispatch('store/init', slug);
	}

	const load = (slug) => {
		store.dispatch('store/check', slug);
	}

	watch(current_store, (newValue) => {
		if(store.getters['code/isDefined'] && newValue.id && _.indexOf(store.getters['code/info'].stores, newValue.id) === -1)
			store.dispatch('code/setCode', null);
	});

	const displayInfo = computed(() => {
		if(isDefined.value) return (_.indexOf(['store', 'market'], current_store.value.type) !== -1);
		return false;
	});

	const showInfo = () => Modal.getInstance(document.getElementById('modal-info')).show();
	const showStatus = () => Modal.getInstance(document.getElementById('modal-status')).show();

	const catalog = computed(() => {
		if(isDefined.value) return store.getters['store/catalog'];
		return [];
	});

	return { isDefined, current_store, services, allowImmediateRequests, allowScheduledRequests, init, load, displayInfo, showInfo, showStatus, catalog };
}
<template>
	<div id="menu-bank-transfer" class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header container container-offcanvas">
			<h5 class="offcanvas-title" id="offcanvasBottomLabel">¿Cómo realizar la transferencia bancaria?</h5>
			<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body container container-offcanvas pt-0">
			<div class="mb-7 vstack gap-2">
				<div class="lh-sm"><b>Monto a transferir:</b> {{ vueNumberFormat(total, $personalizer.currency.value) }}</div>
				<div class="lh-sm text-break text-white-space" v-html="payment_method.description" v-if="payment_method.description"></div>
			</div>
			<div class="d-grid gap-2 d-md-flex justify-content-md-end">
				<button type="button" class="btn btn-sm btn-neutral me-md-2" data-bs-dismiss="offcanvas">Cerrar</button>
				<a target="_blank" v-bind:href="'https://wa.me/'+current_store.phone" class="btn btn-sm btn-dark" v-if="!!current_store.phone">Contactar al comercio</a>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';
	import composableStore from '@/composables/store';

	export default {
		props: {
			payment_method: Object
		},
		setup() {
			const { current_store } = composableStore();
			const total = computed(() => store.getters['cart/total']);

			return { current_store, total };
		}
	}
</script>
<template>
	<div class="py-10 pt-md-24">
		<div class="row align-items-center gy-10">
			<div class="col-12 col-md-6 text-center">
				<slot />
			</div>
			<div class="col-12 col-md-6 text-center">
				<img :src="require(`@/assets/img/illustrations/not-found.png`)" class="img-fluid">
			</div>
		</div>
	</div>
</template>
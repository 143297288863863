<template>
	<div class="row gy-1">
		<div class="col-12 text-center text-md-start">
			<h3 class="ls-tight lh-xs fw-bolder">Productos sugeridos</h3>
			<p class="lh-sm mb-3">Éstos productos pueden interesarte</p>
		</div>
		<div class="col-12 px-0">
			<div class="d-flex gap-2 px-4 scrollable-x">
				<stores-marketing-items-card v-for="item in items" v-bind:key="item.id" :item="item" @select="select" />
			</div>
		</div>
	</div>

	<stores-marketing-items-modal :item="item" @select="select" />
</template>

<script>
	import { ref } from 'vue';

	export default {
		props: {
			items: Array
		},
		setup() {
			const item = ref(null);
			const select = (data) => item.value = data;

			return { item, select };
		}
	}
</script>
<template>
	<div class="d-flex flex-column min-h-full">
		<header>
			<div class="container py-5">
				<div class="align-items-center">
					<common-button-back :redirect="buttonBack" />
				</div>
			</div>
		</header>
		<div class="container flex-fill d-flex flex-column">
			<slot />
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			buttonBack: Object
		}
	}
</script>

<style scoped>
	.container {
		max-width: 550px !important;
	}
</style>
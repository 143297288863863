<template>
	<div class="d-flex justify-content-between align-items-center">
		<div class="d-flex align-items-center">
			<Skeletor width="15" class="stretched-link h5" />
			<Skeletor width="150" class="stretched-link h5 ms-4" />
		</div>
		<div class="text-end">
			<Skeletor width="15" class="btn-link" />
		</div>
	</div>
</template>
<template>
	<footer class="footer mt-auto pt-8 pb-5 bg-dark text-light">
		<div class="container">
			<div class="row gy-5">
				<div class="col-12">
					<div class="h2 text-white mb-3">¿Necesitás Wantti?</div>
					<div class="h4 text-white mb-1">Creá tu tienda en 10 minutos, y empezá a vender ahora mismo.</div>
					<div class="h4 text-white">Contacto por email: <a href="mailto:info@wantti.com" class="text-warning">info@wantti.com</a></div>
				</div>
				<div class="col-12 hstack gap-5">
					<a href="/web" class="btn btn-sm btn-outline-warning" target="_blank">Más información</a>
					<a href="https://partners.wantti.com/stores/create" class="btn btn-sm btn-primary" target="_blank">¡Crear tienda!</a>
				</div>
			</div>
			<div class="row mt-2">
				<div class="col-12">
					<hr />
				</div>
				<div class="col-12 d-flex flex-wrap gap-2 justify-content-between text-sm">
					<div>GLOOBES & COMPANY SRL, CUIT: 30-71545650-4</div>
					<div>Wantti ®</div>
				</div>
			</div>
		</div>
	</footer>
</template>
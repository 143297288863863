<template>
	<div class="d-flex flex-column h-100 bg-chat">
		<!-- Permissions -->
		<assistant-presentations-permissions :requestUser="!hasUser" :requestLocation="(!hasLocation && needLocation)" @redirectBack="redirectBack" v-if="!hasPermission" />
		<!-- Heading -->
		<assistant-header :type="type" :store="store" @redirectBack="redirectBack" v-if="hasPermission" />
		<!-- Messages -->
		<div class="flex-fill container vstack gap-6 pt-5 overflow-y-auto messages-list d-flex flex-column flex-column-reverse" v-if="hasPermission">
			<assistant-presentations-finished v-if="status == 'closed'" />
			<assistant-message-user :message="{ message: message_sending }" v-if="submitting" />
			<assistant-messages :type="type" :store="store" :messages="messages" @selectStore="selectStore" @selectItem="selectItem" v-if="aiChat" />
			<assistant-presentations-small />
		</div>
		<!-- Footer -->
		<div class="position-sticky bottom-0 z-2" v-if="hasPermission">
			<form class="container py-4 hstack gap-2" @submit.prevent="send" v-if="status != 'closed'">
				<div class="flex-fill">
					<input type="text" id="message" v-model="message" class="form-control rounded-pill" placeholder="Escribir mensaje..." @focus="scrollBottom" v-focus>
				</div>
				<button type="submit" class="btn btn-square rounded-pill btn-neutral shadow" :disabled="submitting">
					<i class="bi bi-send text-lg text-muted" v-if="!submitting"></i>
					<div class="spinner-border spinner-border-sm" role="status" v-if="submitting"></div>
				</button>
			</form>
			<div class="container py-4" v-if="status == 'closed'">
				<div class="flex-fill text-center">
					<button type="button" class="btn btn-sm btn-primary border border-2 border-dark" @click="remove">Iniciar otra conversación</button>
				</div>
			</div>
		</div>

		<assistant-suggesteds-stores-modal :store="storeSelected" @selectStore="selectStore" />
		<assistant-suggesteds-items-modal :item="itemSelected" @selectItem="selectItem" />
	</div>
</template>

<script>
	import { ref, watch, computed } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import _ from 'lodash';
	import moment from 'moment';
	import VueScrollTo from 'vue-scrollto';
	import composableForm from '@/composables/form';
	import AiChatRepository from '@/repositories/AiChatRepository';

	export default {
		props: {
			type: String,
			needLocation: Boolean,
			store: Object
		},
		setup(props) {
			const { submitting, catchError } = composableForm();
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const hasLocation = computed(() => store.getters['location/isDefined']);
			const hasPermission = computed(() => (hasUser.value && (!props.needLocation || hasLocation.value)));
			const message = ref(null);
			const message_sending = ref(null);

			const aiChat = computed(() => _.find(store.getters['aiChats/list'], (row) => {
				if(props.type != row.type) return false;
				else if(props.type == 'store' && (props.store.id != row.stores[0] || row.stores.length > 1)) return false;
				else return true;
			}));

			const messages = computed(() => _.orderBy(aiChat.value?.messages, ['id'], ['desc']));

			const status = computed(() => {
				if(!aiChat.value) return 'new';
				else if(moment(aiChat.value.created_at).isBefore(moment().subtract(4, 'hour'))) return 'closed';
				else return 'open';
			});

			const remove = () => {
				store.dispatch('aiChats/removeChat', aiChat.value.id);
			}

			const create = async () => {
				var payload = { type: props.type };

				switch(props.type) {
					case 'general':
						payload.stores = {
							type: 'by_coordinates',
							geolocation: store.getters['location/address'].geolocation
						}
						break;
					case 'store':
						payload.store_id = props.store.id
						break;
				}

				await AiChatRepository.create(payload).then((response) => {
					store.dispatch('aiChats/sync', response.data);
				}).catch((e) => {
					catchError(e, true);
				});
			}

			const send = async () => {
				if(!message.value || submitting.value) return false;

				document.querySelector('#message').focus();
				message_sending.value = message.value;
				message.value = '';
				scrollBottom();

				submitting.value = true;
				if(!aiChat.value) await create();

				await AiChatRepository.message(aiChat.value.id, {
					message: message_sending.value
				}).then((response) => {
					_.forEach(response.data, (message) => store.dispatch('aiChats/addMessage', message));
				}).catch((e) => {
					catchError(e, true);
				});
				submitting.value = false;
			}

			const storeSelected = ref(null);
			const selectStore = (data) => storeSelected.value = data;

			const itemSelected = ref(null);
			const selectItem = (data) => itemSelected.value = data;

			const scrollBottom = () => VueScrollTo.scrollTo('.messages-list', 0, { container: '.messages-list' });

			watch(() => messages.value, () => {
				setTimeout(scrollBottom, 200);
			}, { deep: true });

			const redirectBack = () => {
				switch(props.type) {
					case 'general':
						router.push({ name: 'home' });
						break;
					case 'store':
						router.push({ name: 'store.home', params: { slug: props.store.slug } });
						break;
				}
			}

			return { hasUser, hasLocation, hasPermission, message, message_sending, aiChat, messages, status, submitting, remove, send, storeSelected, selectStore, itemSelected, selectItem, scrollBottom, redirectBack };
		}
	}
</script>

<style scoped>
	.bg-chat {
		background-image: url('~@/assets/img/backgrounds/chat.jpg');
		background-position: 50% 0;
	}
</style>
import { computed } from 'vue';
import store from '@/store';

export default {
	install: (app) => {
		const storeDefined = computed(() => store.getters['store/isDefined']);
		const storeInfo = computed(() => store.getters['store/info']);

		app.config.globalProperties.$personalizer = {
			currency: computed(() => {
				if(storeDefined.value) return { prefix: `${storeInfo.value.personalize.currency.symbol ?? '$'} `, precision: storeInfo.value.personalize.currency.decimals ?? 2 };
				else return { prefix: '$ ', precision: 2 };
			})
		};
	},
};

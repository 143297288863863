<template>
	<teleport to="body">
		<div class="user-menu-wrapper">
			<div id="user-menu" class="offcanvas offcanvas-end" tabindex="-1" aria-labelledby="offcanvasRightLabel">
				<div class="offcanvas-header hstack gap-3">
					<div class="avatar bg-primary text-white rounded-circle">
						<img :src="avatar()" alt="Avatar">
					</div>
					<div class="flex-fill text-sm overflow-hidden">
						<span class="h4 fw-semibold d-block text-truncate">{{ user.fullname }}</span>
						<span class="text-muted d-block text-truncate">{{ user.email }}</span>
					</div>
					<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
				</div>
				<div class="offcanvas-body px-0">
					<ul class="list-group list-group-flush">
						<li class="list-group-item d-flex">
							<a href="javascript:void(0);" @click="routerPush({name: 'code.scan'})" class="d-flex text-dark stretched-link">
								<div class="pe-3 h5"><i class="bi bi-fullscreen"></i></div>
								<div class="flex-fill">Escanear código QR</div>
							</a>
						</li>
						<li class="list-group-item d-flex">
							<a href="javascript:void(0);" @click="routerPush({name: 'order.index'})" class="d-flex text-dark stretched-link">
								<div class="pe-3 h5"><i class="bi bi-card-checklist"></i></div>
								<div class="flex-fill">Mis pedidos</div>
							</a>
						</li>
						<li class="list-group-item d-flex">
							<a href="javascript:void(0);" @click="routerPush({name: 'location.index'})" class="d-flex text-dark stretched-link">
								<div class="pe-3 h5"><i class="bi bi-geo-alt"></i></div>
								<div class="flex-fill">Mis direcciones</div>
							</a>
						</li>
						<li class="list-group-item d-flex">
							<a href="javascript:void(0);" @click="routerPush({name: 'user.phone.update'})" class="d-flex text-dark stretched-link">
								<div class="pe-3 h5"><i class="bi bi-phone"></i></div>
								<div class="flex-fill">Número de teléfono</div>
							</a>
						</li>
						<li class="list-group-item d-flex">
							<a href="javascript:void(0)" @click="logout" class="d-flex text-dark stretched-link">
								<div class="pe-3 h5"><i class="bi bi-box-arrow-right"></i></div>
								<div class="flex-fill">Cerrar sesión</div>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</teleport>

	<user-status-modal-logout />
</template>

<script>
	import { ref, onMounted } from 'vue';
	import router from '@/router';
	import { Modal, Offcanvas } from 'bootstrap';

	export default {
		props: {
			user: Object
		},
		setup(props) {
			const avatar = () => {
				if(props.user.avatar) return props.user.avatar;
				return require('@/assets/img/icons/avatar.jpg');
			}

			const closeMenu = () => {
				if(document.getElementById('user-menu')) {
					var offcanvasShowed = Offcanvas.getInstance(document.getElementById('user-menu'));
					offcanvasShowed.hide();
				}
			}

			const routerPush = (options) => {
				document.getElementById('user-menu').addEventListener('hidden.bs.offcanvas', () => router.push(options));
				closeMenu();
			}

			const logout = () => {
				closeMenu();
				modalLogout.value.show();
			}

			const modalLogout = ref(null);
			onMounted(() => modalLogout.value = Modal.getInstance(document.getElementById('user-status-modal-logout')));

			return { avatar, routerPush, logout };
		}
	}
</script>

<style scoped>
	.offcanvas-end {
		width: 350px !important;
	}

	.list-group-item {
		padding: 1.125rem 1.5rem;
	}
</style>
<template>
	<button class="btn btn-neutral d-flex align-items-center rounded-5 w-100" :disabled="submitting" @click="login">
		<span class="icon icon-sm pe-2">
			<i class="bi bi-envelope"></i>
		</span>
		<div class="flex-fill ms-n5">Continuar con Email</div>
	</button>
</template>

<script>
	import router from '@/router';

	export default {
		props: {
			submitting: Boolean
		},
		setup() {
			const login = () => {
				router.push({ name: 'identify.email' });
			}

			return { login };
		}
	}
</script>
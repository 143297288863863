<template>
	<div class="hstack gap-2 text-sm">
		<div class="spinner-border spinner-border-sm" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
		{{ text }}
	</div>
</template>

<script>
	export default {
		props: {
			text: String
		}
	}
</script>
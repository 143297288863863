<template>
	<div class="row py-10" v-if="loading">
		<common-message-loading class="col-12" text="Cargando tiendas" />
	</div>
	<div class="row g-5 py-10" v-if="!loading && stores.length > 0">
		<div class="col-12 mt-2" v-if="!hasLocation">
			<div class="alert border text-dark" role="alert">
				<div class="d-flex align-items-center">
					<div class="w-rem-8 text-lg">
						<i class="bi bi-question-circle"></i>
					</div>
					<span class="fw-bold">¿Cuál es tu ubicación?</span>
				</div>
				<div class="ps-8">
					<p class="text-sm">Para que podamos recomendarte la tienda más cercana para vos, es necesario que sepamos tu ubicación.</p>
					<ul class="m-0 mt-1 ps-3">
					</ul>
					<div class="mt-2 hstack gap-5">
						<router-link :to="{name: 'location.create'}" class="link-dark fw-semibold"><i class="bi bi-geo-alt me-2"></i>Establecer ubicación</router-link>
						<router-link :to="{name: 'identify.index'}" class="link-dark fw-semibold" v-if="!hasUser"><i class="bi bi-box-arrow-in-right me-2"></i>Identificate</router-link>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-6 col-lg-4" v-for="store in stores" v-bind:key="store.id">
			<store-card-main :store="store" />
		</div>
	</div>
	<div class="row py-10" v-if="!loading && stores.length == 0">
		<common-message-error class="col-12" :text="`No se encontraron tiendas en ${current_store.name}.`" />
	</div>
	<common-modal-redirect :store="redirect_store" />
</template>

<script>
	import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import StoresRepository from '@/repositories/StoresRepository';
	import composableForm from '@/composables/form';
	import { Modal } from 'bootstrap';
	import _ from 'lodash';

	export default {
		props: {
			current_store: Object
		},
		setup(props) {
			const { catchError } = composableForm();
			const hasLocation = computed(() => store.getters['location/isDefined']);
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const redirect_store = ref(null);
			const redirect_modal = ref(null);
			const stores = ref([]);
			const loading = ref(true);
			const timeout = ref(null);

			const getStores = async () => {
				var payload = { type: 'by_headquarters', store_id: props.current_store.id };
				if(hasLocation.value) payload.geolocation = store.getters['location/geolocation'];

				await StoresRepository.getList(payload)
					.then((response) => {
						loading.value = false;

						_.map(response.data, function(store) { store.random = Math.random(); return store; });
						stores.value = _.orderBy(response.data, ['open', 'address.distance', 'random'], ['desc', 'asc', 'asc']);

						if(hasLocation.value && props.current_store.redirect == 'nearest') {
							redirect_store.value = _.head(stores.value);
							modalRedirect();
						}
					}).catch((e) => {
						loading.value = false;
						catchError(e);
					});
			}

			const modalRedirect = () => {
				document.getElementById(`modal-redirect`).addEventListener('shown.bs.modal', () => {
					document.querySelector('.button-progress').style.width = '100%';
					timeout.value = setTimeout(redirect, 5000);
				});

				document.getElementById(`modal-redirect`).addEventListener('hidden.bs.modal', () => {
					if(timeout.value) clearTimeout(timeout.value);
				});

				redirect_modal.value = new Modal(document.getElementById('modal-redirect'));
				redirect_modal.value.show();
			}

			const redirect = () => {
				redirect_modal.value.hide();
				router.push({name: 'store.home', params: {slug: redirect_store.value.slug}});
			}

			onMounted(getStores);
			onBeforeUnmount(() => { if(timeout.value) clearTimeout(timeout.value); });

			return { loading, hasLocation, hasUser, stores, redirect_store };
		}
	}
</script>
<template>
	<div class="h-100 bg-custom">
		<div class="h-100 container d-flex flex-column justify-content-center position-relative">
			<div class="text-center">
				<img src="@/assets/img/logo/dark-complete.png" alt="Logo" class="logo-big-white" v-if="!isDefined" />
				<img :src="microsite.logo_url" class="logo-big-white" alt="Logo" v-else-if="microsite.logo_url">
				<img :src="microsite.store.logo_url" class="avatar-xl rounded rounded-3" alt="Logo" v-else-if="microsite.store.logo_url">
			</div>
			<slot />
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import composableMicrosite from '@/composables/microsite';

	export default {
		setup() {
			const { isDefined, microsite } = composableMicrosite();

			const background = computed(() => {
				if(microsite.value.styles?.header?.background) return microsite.value.styles.header.background;
				else return 'rgba(var(--x-primary-rgb), var(--x-bg-opacity))';
			});

			return { isDefined, microsite, background };
		}
	}
</script>

<style scoped>
	.container {
		max-width: 450px !important;
	}

	.logo-big-white {
		width: 70%;
	}

	.bg-custom {
		--x-bg-opacity: 1;
		background-color: v-bind(background);
	}
</style>
<template>
	<div class="d-flex w-md-75 flex-row-reverse ms-auto">
		<div class="ms-3">
			<div class="avatar avatar-sm rounded-circle" v-show="imageReady">
				<img :src="avatar" @load="imageReady = true" alt="Avatar">
			</div>
			<Skeletor width="36" height="36" class="avatar avatar-sm rounded-circle" v-show="!imageReady" />
		</div>
		<div class="ms-auto">
			<!-- Name -->
			<div class="d-flex align-items-center align-self-center flex-row-reverse">
				<span class="d-block lh-1 text-sm fw-semibold text-heading text-end">{{ user.fullname }}</span>
				<span class="d-block lh-1 mx-3 text-xs text-muted text-end">{{ createdAtForHummans }}</span>
			</div>
			<!-- Text -->
			<div class="d-flex flex-row-reverse mt-2">
				<div class="px-3 py-2 rounded-bottom-2 rounded-top-start-2 text-sm bg-dark text-white text-break text-white-space">
					{{ message.message }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, computed } from 'vue';
	import moment from 'moment';
	import store from '@/store';

	export default {
		props: {
			message: Object
		},
		setup(props) {
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const user = computed(() => store.getters['auth/user']);
			const createdAtForHummans = computed(() => {
				if(props.message?.created_at) return moment(props.message.created_at).format('hh:mm A');
				else return 'Enviando';
			});
			const imageReady = ref(false);

			const avatar = computed(() => {
				if(user.value.avatar) return user.value.avatar;
				return require('@/assets/img/icons/avatar.jpg');
			});

			return { hasUser, user, createdAtForHummans, imageReady, avatar };
		}
	}
</script>
<template>
	<router-view />
</template>

<style>
	components-empty {
		display: flex;
		flex-flow: column;
		height: 100%;
	}
</style>
<template>
	<div>
		<slot />
		<span class="mt-1 invalid-feedback" v-for="error of validation?.$errors ?? []" :key="error.$uid">{{ error.$message }}</span>
	</div>
</template>

<script>
	export default {
		props: {
			inputId: String,
			title: String,
			subtitle: String,
			validation: Object
		}
	}
</script>
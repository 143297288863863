import Repository from "./Repository";

const resource = "/me";

export default {
	me() {
		return Repository.get(`${resource}`)
	},
	update(payload) {
		return Repository.put(`${resource}`, payload);
	},
	updatePhone(payload) {
		return Repository.put(`${resource}/phone`, payload);
	},
	phoneVerificationRequest() {
		return Repository.post(`${resource}/phone_verification`);
	},
	phoneVerificationCheck(code) {
		return Repository.post(`${resource}/phone_verification/${code}`);
	},
	activeOrder(store_id) {
		return Repository.get(`${resource}/active_order`, { params: { store_id: store_id }});
	}
}
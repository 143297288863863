import { createRouter, createWebHistory } from 'vue-router';
import { trackRouter } from 'vue-gtag-next';
import store from '@/store';
import { Offcanvas } from 'bootstrap';
import { Modal } from 'bootstrap';
import { useToast } from 'vue-toastification';
import composableRedirect from '@/composables/redirect';
import composableMicrosite from '@/composables/microsite';

const routes = [
	{
		path: '/',
		name: 'home',
		meta: { setBack: 'a Wantti', setNext: false, checkCart: true, microsite: false },
		component: () => import('@/views/Home.vue')
	},
	{
		path: '/identify',
		meta: { needUser: false },
		children: [
			{
				path: '',
				name: 'identify.index',
				component: () => import('@/views/User/Identify/Index.vue')
			},
			{
				path: 'phone',
				name: 'identify.phone',
				component: () => import('@/views/User/Identify/Phone.vue')
			},
			{
				path: 'email',
				name: 'identify.email',
				component: () => import('@/views/User/Identify/Email.vue')
			},
			{
				path: 'verification',
				name: 'identify.verification',
				component: () => import('@/views/User/Identify/Verification.vue')
			},
			{
				path: 'signin',
				name: 'identify.signin',
				component: () => import('@/views/User/Identify/Signin.vue')
			}
		]
	},
	{
		path: '/social/:provider',
		name: 'social',
		meta: { needUser: false },
		component: () => import('@/views/User/Social.vue')
	},
	{
		path: '/user',
		name: 'user',
		component: () => import('@/components/empty.vue'),
		children: [
			{
				path: 'phone',
				name: 'user.phone',
				meta: { needUser: true },
				component: () => import('@/components/empty.vue'),
				children: [
					{
						path: 'update',
						name: 'user.phone.update',
						component: () => import('@/views/User/Phone/Update.vue')
					},
					{
						path: 'verification',
						name: 'user.phone.verification',
						component: () => import('@/views/User/Phone/Verification.vue')
					}
				]
			},
			{
				path: 'password',
				name: 'user.password',
				meta: { needUser: true },
				component: () => import('@/views/User/Password.vue')
			}
		]
	},
	{
		path: '/order',
		name: 'order',
		meta: { needUser: true },
		component: () => import('@/components/empty.vue'),
		children: [
			{
				path: '',
				name: 'order.index',
				component: () => import('@/views/Order/Index.vue')
			},
			{
				path: ':id',
				name: 'order.view',
				component: () => import('@/views/Order/View.vue')
			}
		]
	},
	{
		path: '/assistant',
		name: 'assistant',
		meta: { setBack: 'al Asistente' },
		component: () => import('@/views/Assistant/Index.vue')
	},
	{
		path: '/location',
		name: 'location',
		component: () => import('@/components/empty.vue'),
		children: [
			{
				path: '',
				name: 'location.index',
				meta: { needUser: true },
				component: () => import('@/views/User/Location/Index.vue')
			},
			{
				path: 'create',
				name: 'location.create',
				component: () => import('@/views/User/Location/Create.vue')
			}
		]
	},
	{
		path: '/cart',
		component: () => import('@/components/empty.vue'),
		children: [
			{
				path: '',
				name: 'cart.detail',
				meta: { needCart: true },
				component: () => import('@/views/Cart/Index.vue')
			},
			{
				path: 'checkout',
				name: 'cart.checkout',
				meta: { setNext: true, needCart: true, preventPopUpAutoOpen: false },
				component: () => import('@/views/Cart/Checkout.vue'),
				beforeEnter: () => {
					var orderRequestFilters = store.getters['auth/orderRequestFilters'];

					if(orderRequestFilters !== null) {
						router.push({name: orderRequestFilters.name});
						return false;
					}
				}
			},
			{
				path: 'card/:gateway',
				name: 'cart.card',
				component: () => import('@/views/Cart/Card.vue')
			},
			{
				path: 'continue',
				name: 'cart.continue',
				meta: { needCart: true },
				component: () => import('@/views/Cart/Continue.vue')
			},
			{
				path: 'complete/:id',
				name: 'cart.complete',
				component: () => import('@/views/Cart/Complete.vue')
			}
		]
	},
	{
		path: '/code',
		component: () => import('@/components/empty.vue'),
		children: [
			{
				path: 'scan',
				name: 'code.scan',
				component: () => import('@/views/Code/Scan.vue')
			},
			{
				path: ':code',
				name: 'code.load',
				component: () => import('@/views/Code/Load.vue')
			}
		]
	},
	{
		path: '/:slug',
		meta: { checkCart: true },
		component: () => import('@/components/store/core.vue'),
		children: [
			{
				path: '',
				name: 'store.home',
				meta: { setBack: 'al comercio', setNext: false },
				component: () => import('@/views/Store/Home.vue')
			},
			{
				path: 'assistant',
				name: 'store.assistant',
				meta: { setBack: 'al Asistente' },
				component: () => import('@/views/Store/Assistant.vue')
			},
			{
				path: 'links',
				name: 'store.links',
				component: () => import('@/views/Store/Links.vue')
			},
			{
				path: 'item/:id',
				name: 'store.item',
				component: () => import('@/views/Catalog/Item/Detail.vue')
			}
		]
	},
	{
		path: '/not_site',
		name: 'not_site',
		component: () => import('@/views/Pages/NotSite.vue')
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'not_found',
		component: () => import('@/views/Pages/NotFound.vue')
	}
];

window.popStateDetected = false;
window.addEventListener('popstate', () => window.popStateDetected = true);

const router = createRouter({
	history: createWebHistory('/'),
	routes: routes,
	scrollBehavior(to, from, savedPosition) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(savedPosition || { left: 0, top: 0 });
			}, 300);
		});
	}
});

router.beforeEach((to) => {
	const IsItABackButton = window.popStateDetected;
	window.popStateDetected = false;

	if(document.querySelector('.offcanvas.show')) {
		var offcanvasShowed = Offcanvas.getInstance(document.querySelector('.offcanvas.show'));
		offcanvasShowed.hide();

		if(IsItABackButton) return false;
	}

	if(document.querySelector('.modal.show')) {
		var modalShowed = Modal.getInstance(document.querySelector('.modal.show'));
		modalShowed.hide();

		if(IsItABackButton) return false;
	}

	if(to.meta.checkCart && store.getters['cart/isDefined'] && store.getters['cart/slug'] != to.params.slug) {
		router.push({ name: 'cart.continue' });
	}

	if(to.meta?.needUser === true && !store.getters['auth/hasUser']) {
		const toast = useToast();

		toast.error('Debes estar logueado.');
		router.push({ name: 'login' });
	}

	if(to.meta?.needUser === false && store.getters['auth/hasUser']) {
		const { redirectBack } = composableRedirect();
		redirectBack();
	}

	if(to.meta.needLocation && !store.getters['location/isDefined']) {
		const toast = useToast();

		toast.error('Debes ingresar una ubicación.');
		router.push({ name: 'location.create' });
	}

	if(to.meta.needCart && !store.getters['cart/isDefined']) {
		const { redirectBack } = composableRedirect();
		redirectBack();
	}

	if(to.meta?.microsite === false) {
		const { isMicrosite, microsite } = composableMicrosite();
		if(isMicrosite.value) router.push({ name: 'store.home', params: { slug: microsite.value.store.slug }});
	}
});

router.afterEach((to) => {
	if(to.meta.setBack) {
		store.dispatch('redirect/setBack', { description: to.meta.setBack, name: to.name, path: to.path });
	}

	if(to.meta?.setNext === true) {
		store.dispatch('redirect/setNext', { path: to.path });
	}

	if(to.meta?.setNext === false) {
		store.dispatch('redirect/setNext', null);
	}
});

trackRouter(router);

export default router

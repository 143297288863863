import router from '@/router';
import moment from 'moment';
import _ from 'lodash';
import StoreRepository from '@/repositories/StoreRepository';

export default {
	namespaced: true,
	state: {
		info: null,
		services: null,
		user_verification: [],
		discounts: [],
		catalog: {
			sections: [],
			items: []
		},
		expires_in: null
	},
	getters: {
		isDefined(state) {
			return !!state.info;
		},
		info(state) {
			return state.info;
		},
		services(state) {
			return state.services;
		},
		findServices: (state) => (type) => {
			return _.find(state.services, (row) => { return row.type == type });
		},
		userVerification(state) {
			return state.user_verification;
		},
		discounts(state) {
			return state.discounts;
		},
		catalog(state) {
			return state.catalog;
		},
		allowOrderRequests(state) {
			var available_services = _.find(state.services, (service) => { return service.status == 'available'; });
			return (available_services != undefined);
		},
		allowImmediateRequests(state) {
			var available_immediate = _.find(state.services, (service) => {
				if(service.status == 'unavailable') return false;
				return _.find(service.options, (option) => { return (option.type == 'immediate' && option.status == 'available'); });
			});

			return (available_immediate != undefined);
		},
		allowScheduledRequests(state) {
			var available_scheduled = _.find(state.services, (service) => {
				if(service.status == 'unavailable') return false;
				return _.find(service.options, (option) => { return (option.type == 'scheduled' && option.status == 'available'); });
			});

			return (available_scheduled != undefined);
		},
		allowTakeAway(state, getters) {
			var service = getters.findServices('TAKEAWAY');
			return (service != undefined && service.status == 'available');
		},
		expires_in(state) {
			return state.expires_in;
		}
	},
	actions: {
		async init({ dispatch }, slug) {
			try {
				var store = await StoreRepository.getProfile(slug);
				var extras = await StoreRepository.getExtras(slug);

				dispatch('setStore', store.data.data);
				dispatch('setSections', store.data.catalog.sections);
				dispatch('setItems', store.data.catalog.items);
				dispatch('setServices', extras.data.services);
				dispatch('setUserVerification', extras.data.user_verification);
				dispatch('setDiscounts', extras.data.discounts);
				dispatch('setExpires');

				dispatch('marketing/setLanding', store.data.data.id, { root: true });

				return Promise.resolve();
			}catch(error) {
				router.push({name: 'home'});
				return Promise.reject();
			}
		},
		check({ state, dispatch, commit }, slug) {
			if(state.info == null || state.info.slug != slug || !state.expires_in || moment(state.expires_in).isBefore(moment())) {
				commit('setStore', null);
				dispatch('init', slug);
			}
		},
		setStore({ commit }, info) {
			commit('setStore', info);
		},
		setServices({ commit }, services) {
			commit('setServices', services);
		},
		setUserVerification({ commit }, verification) {
			commit('setUserVerification', verification);
		},
		setDiscounts({ commit }, discounts) {
			commit('setDiscounts', discounts);
		},
		setSections({ commit }, sections) {
			commit('setSections', sections);
		},
		setItems({ commit }, items) {
			commit('setItems', items);
		},
		setExpires({ commit }) {
			commit('setExpires',  moment().add(15, 'minutes').format());
		}
	},
	mutations: {
		setStore(state, info) {
			state.info = info;
		},
		setServices(state, services) {
			state.services = services;
		},
		setUserVerification(state, verification) {
			state.user_verification = verification;
		},
		setDiscounts(state, discounts) {
			state.discounts = discounts;
		},
		setSections(state, sections) {
			state.catalog.sections = sections;
		},
		setItems(state, items) {
			state.catalog.items = items;
		},
		setExpires(state, expires_in) {
			state.expires_in = expires_in;
		}
	}
}
<template>
	<div class="row align-items-center">
		<div class="col-12 col-lg-8 mb-10 mb-lg-0 ps-lg-20 order-lg-2">
			<h3 class="mb-2 text-uppercase text-success">Bienvenido a nuestro</h3>
			<h1 class="fw-bolder display-3 ls-tight lh-xxs mb-5">Asistente virtual</h1>
			<h2 class="fw-semibold mb-2">¿En qué podemos ayudarte?</h2>
			<h2 class="fw-semibold text-muted">Probá buscando información sobre comercios, productos, recomendaciones y más.</h2>
		</div>
		<div class="col-12 col-lg-4 order-lg-1">
			<img src="@/assets/img/ai/full.png" class="img-fluid" alt="Assistant" />
		</div>
	</div>
</template>
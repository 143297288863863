<template>
	<teleport to="body">
		<div class="modal" id="assistant-suggesteds-items-modal" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content" v-if="item">
					<div class="modal-header p-0 border-bottom-0" v-if="!!item.image">
						<div class="container bg-cover store-cover rounded-top position-relative p-3" :style="{'background-image': `url(${item.image_md_url})`}" v-if="!imageSkeletor && !!item.image_md_url"></div>
						<Skeletor height="230" class="container bg-cover store-cover rounded-top" v-if="imageSkeletor" />
					</div>
					<div class="modal-body">
						<div id="item-description">
							<div class="d-flex justify-content-between">
								<h3 class="h3">{{ item.name }}</h3>
								<div class="ms-3">
									<h3 class="h3 text-nowrap">{{ vueNumberFormat(item.price, $personalizer.currency.value) }}</h3>
								</div>
							</div>
						</div>
						<p class="text-muted pt-3 text-break text-white-space">{{ item.description }}</p>
						<div class="vstack gap-3 mt-4">
							<div class="hstack gap-3" v-for="store in item.stores" v-bind:key="store.id">
								<store-card-small class="flex-fill" :store="store" />
								<router-link :to="{ name: 'store.item', params: { slug: store.slug, id: item.id } }" class="btn btn-sm btn-neutral">Ver</router-link>
							</div>
						</div>
					</div>
					<div class="modal-footer d-flex justify-content-between">
						<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Cerrar</button>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, watch, onMounted } from 'vue';
	import { Modal } from 'bootstrap';

	export default {
		emits: ['selectItem'],
		props: {
			item: Object
		},
		setup(props, { emit }) {
			const modalItem = ref(null);
			onMounted(() => {
				modalItem.value = new Modal(document.getElementById('assistant-suggesteds-items-modal'));
				document.getElementById('assistant-suggesteds-items-modal').addEventListener('hidden.bs.modal', () => {
					emit('selectItem', null);
				});
			});

			const imageSkeletor = ref(true);
			watch(() => props.item, (newValue) => {
				if(newValue) {
					modalItem.value.show();
					imageSkeletor.value = !!props.item.image_md_url;

					if(props.item.image_md_url != null) {
						var loadImage = new Image();
						loadImage.onload = function() { imageSkeletor.value = false; };
						loadImage.src = props.item.image_md_url;
					}
				}
			});

			return { imageSkeletor };
		}
	}
</script>

<style scoped>
	.store-cover {
		height: 230px;
		background-position: center;
	}

	.avatar {
		background-size: cover !important;
		background-position: center !important;
	}

	.badge {
		padding: .24rem .5rem;
	}
</style>
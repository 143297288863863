<template>
	<div class="d-flex align-items-center list-group-item px-3">
		<div class="d-flex align-items-center">
			<a href="javascript:void(0);" class="me-4 text-dark" @click="deleteDialog"><i class="bi bi-trash"></i></a>
		</div>
		<div class="d-flex flex-fill justify-content-between position-relative align-items-center">
			<div>
				<b class="me-2" v-if="address.pivot.description">{{ address.pivot.description }}</b>
				<span class="mb-0">{{ complete() }}</span>
			</div>
			<div class="text-end">
				<a href="javascript:void(0);" class="btn-link text-muted stretched-link" @click="selectAddress">
					<svg class="bi bi-chevron-right" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
					</svg>
				</a>
			</div>
		</div>

		<teleport to="body">
			<div class="modal" :id="`modal-delete-${address.address_id}`" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
				<div class="modal-dialog modal-sm modal-dialog-centered">
					<div class="modal-content shadow-4">
						<div class="modal-body text-center">
							<h4 class="mb-1">¿Eliminar dirección?</h4>
							<p class="text-sm text-muted">
								<b class="me-2" v-if="address.pivot.description">{{ address.pivot.description }}</b>{{ complete() }}
							</p>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-sm btn-neutral" data-bs-dismiss="modal">Cancelar</button>
							<button type="button" class="btn btn-sm btn-danger" :disabled="submitting" @click="deleteAddress">Eliminar</button>
						</div>
					</div>
				</div>
			</div>
		</teleport>
	</div>
</template>

<script>
	import { ref, onMounted, onBeforeUnmount } from 'vue';
	import store from '@/store';
	import LocationRepository from '@/repositories/LocationRepository';
	import composableRedirect from '@/composables/redirect';
	import composableForm from '@/composables/form';
	import { useToast } from 'vue-toastification';
	import { Modal } from 'bootstrap';

	export default {
		props: {
			address: Object
		},
		setup(props, {emit}) {
			const { submitting, catchError } = composableForm();
			const toast = useToast();
			const complete = () => {
				var data = [];

				if(props.address.route) data.push(props.address.route);
				if(props.address.street_number) data.push(`Nro. ${props.address.street_number}`);
				if(props.address.division) data.push(`(${props.address.division})`);

				return (data.length > 0) ? data.join(' ') : 'Sin descripción';
			}

			//Modal de eliminación del producto
			const modal = ref(null);
			onMounted(() => modal.value = new Modal(document.getElementById(`modal-delete-${props.address.address_id}`)));
			onBeforeUnmount(() => {
				modal.value.hide();
				modal.value.dispose();
			});
			const deleteDialog = () => modal.value.show();
			const deleteAddress = () => {
				submitting.value = true;
				LocationRepository.destroy(props.address.address_id).then(() => emit('reload')).catch(catchError);
			}

			const { redirectNext } = composableRedirect();
			const selectAddress = () => {
				LocationRepository.select(props.address.address_id).then(setAddress).catch(catchError);
			}

			const setAddress = () => {
				store.dispatch('location/setAddress', {
						id: props.address.address_id,
						description: props.address.pivot.description,
						country: props.address.country,
						administrative_area_level_1: props.address.administrative_area_level_1,
						administrative_area_level_2: props.address.administrative_area_level_2,
						administrative_area_level_3: props.address.administrative_area_level_3,
						locality: props.address.locality,
						sublocality: props.address.sublocality,
						route: props.address.route,
						street_number: props.address.street_number,
						postal_code: props.address.postal_code,
						division: props.address.division,
						geolocation: {
							lat: props.address.geolocation.coordinates[0],
							lng: props.address.geolocation.coordinates[1]
						}
					}).then(() => {
						toast.success(`Nueva ubicación seleccionada.`);
						redirectNext();
					}).catch(catchError);
			}

			return { complete, submitting, deleteDialog, deleteAddress, selectAddress };
		}
	}
</script>
import _ from 'lodash';

export default {
	namespaced: true,
	state: {
		stories: []
	},
	getters: {
		stories(state) {
			return state.stories;
		}
	},
	actions: {
		addStory({ state, commit }, id) {
			var stories = _.clone(state.stories);
			if(_.indexOf(stories, id) == -1) stories.push(id);

			commit('setStories', stories);
		}
	},
	mutations: {
		setStories(state, stories) {
			state.stories = stories;
		}
	}
}
import { computed } from 'vue';
import store from '@/store';

export default function() {
	var appUrls = ['wantti.com', 'pedidos-sta.wantti.com', 'localhost', 'localhost:8080', '192.168.0.235', '192.168.0.235:8080'];
	//var appUrls = ['wantti.com', 'pedidos-sta.wantti.com'];

	const isMicrosite = computed(() => (appUrls.indexOf(window.location.host) === -1));
	const isDefined = computed(() => store.getters['microsite/isDefined']);
	const microsite = computed(() => store.getters['microsite/data']);
	const init = () => store.dispatch('microsite/init');

	return { isMicrosite, isDefined, microsite, init };
}
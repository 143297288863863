<template>
	<div class="row my-3" v-if="loading">
		<common-message-loading class="col-12" text="Cargando tiendas" />
	</div>
	<div class="row g-5 pt-5 pb-8" v-if="!loading && stores.length > 0">
		<div class="col-12 col-md-6 col-lg-4" v-for="store in stores" v-bind:key="store.id">
			<store-card-main :store="store" />
		</div>
	</div>
	<div class="row my-3" v-if="!loading && stores.length == 0">
		<common-message-error class="col-12" :text="`No se encontraron tiendas en ${current_store.name}.`" />
	</div>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import store from '@/store';
	import StoresRepository from '@/repositories/StoresRepository';
	import composableForm from '@/composables/form';
	import _ from 'lodash';

	export default {
		props: {
			current_store: Object
		},
		setup(props) {
			const { catchError } = composableForm();
			const stores = ref([]);
			const loading = ref(true);

			const getStores = async () => {
				var payload = { type: 'by_market', store_id: props.current_store.id };
				if(store.getters['code/isDefined']) payload.stores = store.getters['code/info'].stores;

				await StoresRepository.getList(payload)
					.then((response) => {
						loading.value = false;
						stores.value = _.shuffle(response.data);
					}).catch((e) => {
						loading.value = false;
						catchError(e);
					});
			}

			onMounted(getStores);

			return { loading, stores };
		}
	}
</script>
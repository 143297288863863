import Repository from "./Repository";

const resource = "/users";

export default {
	signin(payload) {
		return Repository.post(`${resource}/signin`, payload);
	},
	identify(payload) {
		return Repository.post(`${resource}/identify`, payload);
	}
}
<template>
	<div id="store-body" class="row" v-if="isDefined">
		<div class="col-12 nav-sections" v-if="sections.length > 1">
			<ul class="horizontal-tags nav">
				<li class="nav-item" v-for="section in sections" :id="`button-${section.id}`" v-bind:key="section.id">
					<a href="javascript:void(0);" class="btn btn-tag" :class="{'active': (onViewport.indexOf(section.id) !== -1)}" @click="showSection(section.id)">{{ section.name }}</a>
				</li>
			</ul>
		</div>
		<div class="col-12 vstack gap-10 pt-5 pb-24" v-if="sections.length > 0">
			<catalog-section v-for="section in sections" v-bind:key="section.id" :section="section"></catalog-section>
		</div>
		<common-message-empty class="col-12" v-if="sections.length == 0">
			<div class="px-5">
				<div class="lh-xs ls-tight display-5 text-dark">Ups!</div>
				<div class="h3 mt-3">El comercio no cargó su catálogo aún.</div>
			</div>
		</common-message-empty>
	</div>
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import store from '@/store';
	import _ from 'lodash';
	import VueScrollTo from 'vue-scrollto';
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const { isDefined, catalog } = composableStore();
			const sections = computed(() => {
				if(isDefined.value) {
					var results = catalog.value.sections;
					var type = (store.getters['code/isDefined'] && _.find(results, (row) => { return row.type == store.getters['code/type']; }) !== undefined) ? store.getters['code/type'] : 'ONLINE';

					return _.filter(results, (section) => {
						return (section.type == type && _.find(catalog.value.items, { section_id: section.id }));
					});
				}

				return [];
			});

			const showSection = (id) => {
				VueScrollTo.scrollTo(`#section-${id}`, 150, { offset: -145 });
			}

			const onViewport = ref([]);
			onMounted(() => {
				const observer = new IntersectionObserver(
					(entries) => {
						_.forEach(entries, (entry) => {
							var id = Number(entry.target.getAttribute('section-id'));

							if(entry.isIntersecting && _.indexOf(onViewport.value, id) === -1) {
								onViewport.value.push(id);
							}else if(!entry.isIntersecting) {
								var index = _.indexOf(onViewport.value, id);
								if(index !== -1) onViewport.value.splice(index, 1);
							}
						});

						setTimeout(() => {
							var el = document.querySelector('.horizontal-tags .btn.active:first-child');
							if(el) el.parentNode.parentNode.scrollLeft = el.offsetLeft - 24;
						}, 100);
					}, { root: document, rootMargin: '-200px 0px -130px 0px', }
				);

				_.forEach(document.getElementsByClassName('section-content'), (element) => observer.observe(element));
			});

			return { isDefined, sections, showSection, onViewport };
		}
	}
</script>

<style scoped>
	.nav-sections {
		position: sticky;
		position: -webkit-sticky;
		top: 70px;
		z-index: 1020;
		background-color: var(--bs-body-bg) !important;
		padding-top: .75rem !important;
		padding-bottom: .5rem !important;
	}
</style>